import React, { useState } from "react";
import Loader from "./Loader";

const ShippingAddressTab = ({
  addAddressHandler,
  updateAddressHandler,
  shippingAddress = [],
  addressSubmitting,
  showShippingForm,
  setShowShippingForm,
  formData,
  setFormData,
  deleteAddressHandler,
}) => {
  //   console.log("Bil as", JSON.stringify(shippingAddesss) == "{}");

  const [updatingData, setUpdatingData] = useState(false);

  return (
    <>
      <div className="d-flex pb-3 justify-content-between">
        <h3 className="p-0 m-0">Shipping Address</h3>
        <a
          href="#"
          onClick={(evt) => {
            evt.preventDefault();
            setShowShippingForm(true);
            setUpdatingData(false);
            setFormData({
              name: "",
              email: "",
              address: "",
              mobile: "",
              city: "",
              defaultAddress: false,
              addressType: "",
              pincode: "",
              companyName: "",
            });
          }}
          className="btn d-inline-block edit-address-btn"
        >
          <i className="fa fa-plus"></i>Add Address
        </a>
      </div>

      <div className="row">
        {shippingAddress.length
          ? !showShippingForm
            ? shippingAddress.map((item, index) => {
                return (
                  <div className="col-md-6" key={`address-${index}`}>
                    <address className="card card-body">
                      <p>
                        <strong>{item.name}</strong>
                        <span className="d-block">Email: {item.email}</span>
                        <span className="d-block">Mobile: {item.mobile}</span>
                      </p>
                      <p>
                        <p className="p-0 m-0">{item.address}</p>
                        <p className="p-0 m-0">
                          {item.city} {item.pincode}
                        </p>
                        <p className="p-0 m-0">{item.landmark}</p>
                        <p className="p-0 m-0"> Type: {item.addressType}</p>
                      </p>

                      <div className="d-flex" style={{ gap: 10 }}>
                        <a
                          href="#"
                          onClick={(evt) => {
                            evt.preventDefault();
                            setShowShippingForm(true);
                            setUpdatingData(true);
                            setFormData({ ...item });
                          }}
                          className="btn d-inline-block edit-address-btn"
                        >
                          <i className="fa fa-edit"></i>Edit Address
                        </a>

                        <a
                          href="#"
                          onClick={(evt) => {
                            deleteAddressHandler(evt, item._id);
                          }}
                          className="btn d-inline-block edit-address-btn"
                          style={{ background: "#d63031" }}
                        >
                          <i className="fa fa-trash"></i>
                          {"Delete Address"}
                        </a>
                      </div>
                    </address>
                  </div>
                );
              })
            : ""
          : ""}
      </div>

      {!shippingAddress.length ? (
        !showShippingForm ? (
          <div className="">
            <div className="alert alert-danger">
              No Shipping Address Available !!
            </div>
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {showShippingForm ? (
        <div className="account-details-form">
          <form
            action="#"
            onSubmit={(evt) => {
              if (updatingData) {
                updateAddressHandler(evt, formData);
              } else {
                addAddressHandler(evt, formData);
              }
              // if (addAddressHandler(evt, formData)) {
              //   setFormData({});
              //   showShippingForm(false);
              // }
            }}
          >
            <div className="row">
              <div className="col-lg-6 col-12">
                <input
                  id="first-name"
                  value={formData.name}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      name: evt.target.value,
                    });
                  }}
                  placeholder="Full Name"
                  type="text"
                />
              </div>

              <div className="col-lg-6 col-12">
                <input
                  value={formData.mobile}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      mobile: evt.target.value,
                    });
                  }}
                  placeholder="Mobile"
                  type="text"
                />
              </div>

              <div className="col-12">
                <input
                  id="email"
                  value={formData.email}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      email: evt.target.value,
                    });
                  }}
                  placeholder="Email Address"
                  type="email"
                />
              </div>

              <div className="col-lg-6 col-12">
                <input
                  value={formData.address}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      address: evt.target.value,
                    });
                  }}
                  placeholder="Full Address"
                  type="text"
                />
              </div>

              <div className="col-lg-6 col-12">
                <input
                  value={formData.city}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      city: evt.target.value,
                    });
                  }}
                  placeholder="City"
                  type="text"
                />
              </div>
              <div className="col-lg-6 col-12">
                <input
                  value={formData.pincode}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      pincode: evt.target.value,
                    });
                  }}
                  placeholder="Pincode"
                  type="number"
                />
              </div>

              <div className="col-lg-6 col-12">
                <input
                  value={formData.landmark}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      landmark: evt.target.value,
                    });
                  }}
                  placeholder="Landmark"
                  type="text"
                />
              </div>

              <div className="col-lg-6 col-12 mb-3">
                <div key={""} class="single-method d-flex">
                  <div className="pr-3">
                    <input
                      type="radio"
                      id={"home"}
                      name="shippingAddress"
                      checked={formData.addressType == "HOME" ? true : false}
                      onChange={(evt) => {
                        setFormData({
                          ...formData,
                          addressType: evt.target.value,
                        });
                      }}
                      value="HOME"
                    />
                    <label for={"home"}>HOME</label>
                  </div>

                  <div className="px-3">
                    <input
                      type="radio"
                      id={"office"}
                      checked={formData.addressType == "OFFICE" ? true : false}
                      name="shippingAddress"
                      onChange={(evt) => {
                        setFormData({
                          ...formData,
                          addressType: evt.target.value,
                        });
                      }}
                      value="OFFICE"
                    />
                    <label for={"office"}>OFFICE</label>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-6 col-12 mb-3">
                <div key={""} class="single-method d-flex">
                  <div className="pr-3">
                    <input
                      type="checkbox"
                      id={"defaultAddress"}
                      checked={formData.defaultAddress == true ? true : false}
                      name="defaultAddress"
                      onChange={(evt) => {
                        setFormData({
                          ...formData,
                          defaultAddress: !formData.defaultAddress,
                        });
                      }}
                      value={true}
                    />
                    <label for={"defaultAddress"}>DEFAULT ADDRESS</label>
                  </div>
                </div>
              </div> */}

              <div className="col-12 d-flex">
                <div className="">
                  <button
                    className="save-change-btn"
                    disabled={addressSubmitting ? true : false}
                  >
                    {addressSubmitting ? <Loader /> : "Submit"}
                  </button>
                </div>

                <div className="">
                  <div style={{ marginLeft: "10px" }}>
                    <button
                      type="button"
                      onClick={(evt) => {
                        evt.preventDefault();
                        setShowShippingForm(false);
                      }}
                      className="save-change-btn bg-danger"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ShippingAddressTab;
