import React, { useState, useEffect } from "react";
import { SERVER_URL } from "../../config";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const BannerContainer = ({ onClickHandler = () => {} }) => {
  const [offerBanners, setOfferBanners] = useState([]);
  const [imageSliderLoading, setImageSliderLoading] = useState(false);

  // Get Offer Banner
  useEffect(() => {
    fetch(`${SERVER_URL}/settings`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setImageSliderLoading(false);

        if (data.status == 200) {
          setOfferBanners(data.body.offerBanner || []);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setImageSliderLoading(false);
        // toast.warning(error);
      });
  }, []);
  return (
    <div className="banner-hover-area section-space">
      <div className="container wide">
        <div className="row">
          <div className="col-lg-12">
            {/*-=======  banner hover wrapper  =======*/}
            <div className="banner-hover-wrapper">
              <div className="row">
                {offerBanners.map((banner, index) => {
                  return (
                    <div className="col-md-6" id={`banner-${index}`}>
                      {/*-=======  single hover banner  =======*/}
                      <div className="single-hover-banner single-hover-banner--middlesize-text">
                        <div className="single-hover-banner__image">
                          <img
                            onClick={onClickHandler}
                            style={{ cursor: "pointer" }}
                            width="895"
                            height="430"
                            src={banner.image}
                            className="img-fluid"
                            alt=""
                          />
                          {/* {banner.webpageUrl ? (
                            banner.webpageUrl.includes("http") ? (
                              <a
                                href={`${banner.webpageUrl}`}
                                target={"_blank"}
                              >
                               
                              </a>
                            ) : (
                              <Link to={banner.webpageUrl}>
                                <img
                                  width="895"
                                  height="430"
                                  src={banner.image}
                                  className="img-fluid"
                                  alt=""
                                />
                              </Link>
                            )
                          ) : (
                            ""
                          )}
                          <div className="single-hover-banner--middlesize-text__content">
                            <h4
                              className="small-text"
                              style={{ color: banner.textColor }}
                            >
                              {banner.subTitle}
                            </h4>
                            <h3
                              className="big-text"
                              style={{ color: banner.textColor }}
                            >
                              {banner.title}
                            </h3>

                            {banner.webpageUrl ? (
                              banner.webpageUrl.includes("http") ? (
                                <a
                                  className="banner-link"
                                  style={{
                                    color: banner.btnTextColor,
                                    borderColor: banner.btnTextColor,
                                  }}
                                  href={banner.webpageUrl}
                                ></a>
                              ) : (
                                <Link
                                  className="banner-link"
                                  style={{
                                    color: banner.btnTextColor,
                                    borderColor: banner.btnTextColor,
                                  }}
                                  to={banner.webpageUrl}
                                >
                                  {banner.btnText || "SHOP NOW"}
                                </Link>
                              )
                            ) : (
                              ""
                            )} */}
                          {/* </div> */}
                        </div>
                      </div>
                      {/*-=======  End of single hover banner  =======*/}
                    </div>
                  );
                })}
              </div>
            </div>
            {/*-=======  End of banner hover wrapper  =======*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerContainer;
