import React, { useEffect, useState } from "react";
import CategoryCard from "../components/CategoryCard";
import { toast } from "react-toastify";
import { SERVER_URL } from "../config";
import parse from "html-react-parser";
import Spinner from "../components/Spinner";
import Slider from "react-slick/lib/slider";
import NewsLater from "../components/NewsLater";
import Breadcrumb from "../components/Breadcrumb";
import Header from "../layouts/Header";
import HelmetMetaData from "../components/HelmetMetaData";
// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = false;
  document.body.appendChild(script);
}

var settings3 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: true,
  autoplaySpeed: 5000,
  initialSlide: 0,
  // prevArrow: <SampleNextArrow />,
  // nextArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

const About = () => {
  const [aboutDetailsLoading, setAboutDetailsLoading] = useState(true);
  const [aboutDetails, setAboutDetails] = useState([]);

  const [whyPointsLoading, setWhyPointsLoading] = useState(true);
  const [whyPoints, setWhyPoints] = useState([]);

  const [parCategories, setParentcategories] = useState([]);
  const [parCatLoading, setParCatLoading] = useState(true);

  // Get About Us details
  useEffect(() => {
    setAboutDetailsLoading(true);
    fetch(`${SERVER_URL}/aboutUs/`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAboutDetailsLoading(false);
        if (data.status == 200) {
          setAboutDetails(data.body || []);
        } else {
          toast.warning(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setAboutDetailsLoading(false);
        toast.warning(error);
      });
  }, []);

  // Get All Parent Categoies
  useEffect(() => {
    setParCatLoading(true);
    fetch(`${SERVER_URL}/parentCategories`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setParCatLoading(false);
        if (data.status == 200) {
          setParentcategories(data.body || []);
        } else {
          toast.warning(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setParCatLoading(false);
        toast.warning(error);
      });
  }, []);

  // Get Why Awni Points details
  useEffect(() => {
    setWhyPointsLoading(true);
    fetch(`${SERVER_URL}/whyAwni/`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setWhyPointsLoading(false);
        if (data.status == 200) {
          setWhyPoints(data.body || []);
        } else {
          toast.warning(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setWhyPointsLoading(false);
        toast.warning(error);
      });
  }, []);

  // Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HelmetMetaData
        title={"About us | Ferra Indica"}
        description={"About us | Ferra Indica"}
      />
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb title={"About Us"} page={"about"} />
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div class="page-content-area">
        <div class="container  pt-5">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div class="page-wrapper pt-5">
                <div class="">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="about-top-content-wrapper">
                        <div class="row row-30">
                          {/* About Image */}
                          <div class="col-lg-6">
                            <div class="about-image">
                              <img
                                width="570"
                                height="319"
                                src="/assets/img/about.jpg"
                                class="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>

                          {/* About Content */}
                          <div class="about-content col-lg-6">
                            <div class="row">
                              <div class="col-12">
                                <h1>
                                  OUR STORY
                                  <br />
                                </h1>
                                <p className="mb-3">
                                  A man travels the world over in search of what
                                  he needs and returns home to find it.
                                </p>
                                <p>~George A. Moore</p>
                                <p>
                                  And thus began our journey into the heartland
                                  of our own country to find that - sustainable
                                  ecosystems are not just words but a way of
                                  life for people who still feel the earth
                                  beneath their bare feet and continue to craft
                                  through generations of ancient arts that have
                                  lost nothing of the magic or wisdom of
                                  masters.
                                </p>

                                <p>
                                  We feel this heritage must not be lost and the
                                  only way to remain viable would be to remain
                                  ethical in economics and innovative in
                                  approach.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 mt-3 about-content mb-5">
                            <p>
                              We are curating each collection as limited
                              editions with an eye on maintaining international
                              standards in quality, presentation & packaging.
                            </p>
                            <p>
                              From our heartland into your home we bring you the
                              very best from renowned artisans with the hope
                              that the business will encourage & support the
                              succeeding generation of artisans.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* =======  End of page wrapper  ======= */}
            </div>
          </div>
        </div>

        <section class="gry-bg">
          <div class="">
            <div class="container">
              <div class="row">
                <div class="about-content col-lg-6">
                  <h2>
                    SUNETRA LAHIRI
                    <br />
                    <span> FOUNDER &amp; CREATIVE DIRECTOR</span>
                  </h2>
                  <h4>Sunetra Lahiri is an alumna of Nift 1999.</h4>
                  <p>
                    Sixteen years of being a "designer" &amp; then she
                    experienced a paradigm shift in thought, philosophy &amp;
                    objective
                  </p>
                  <p>
                    Handcrafted native, aboriginal folk art on woven textiles
                    has always inspired her. That's how she started by exploring
                    the world of craft whose DNA is embedded in our culture,
                    heritage, as well as nature.{" "}
                  </p>
                  <p>
                    Using her artistic senses &amp; knowledge of different craft
                    forms she wanted to address issues of environmental
                    sustainability &amp; the transcendental quality of
                    traditional craft forms. She is curating each collection as
                    limited editions with an eye on maintaining international
                    standards in quality, presentation &amp; packaging.
                  </p>
                  <p>
                    From our heartland into your home, her endeavor is to bring
                    you the very best from renowned artisans with the hope that
                    the business will encourage &amp; support the succeeding
                    generation of artisans.
                  </p>
                </div>

                <div class="col-lg-6">
                  <div class="about-image">
                    <img
                      width="570"
                      height="319"
                      src="assets/img/Sunetra-Lahiri.jpg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="container pt-5">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div class="">
                <div class="">
                  <div class="row">
                    <div class="col-lg-12 pt-5">
                      <div class="about-top-content-wrapper">
                        <div class="row row-30">
                          {/* About Image */}
                          <div class="col-lg-6">
                            <div class="about-image">
                              <img
                                width="570"
                                height="319"
                                src="assets/img/Sudeshna-Mitra.jpg"
                                class="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>

                          {/* About Content */}
                          <div class="about-content col-lg-6">
                            <div class="row">
                              <div class="col-12">
                                <h1>
                                  SUDESHNA MITRA
                                  <br />
                                  <span>FOUNDER .</span>
                                </h1>
                                <p class="mb-2">
                                  Sudeshna Mitra has an affinity with numbers
                                  which led her to pursue degrees in
                                  Mathematics, Artificial Intelligence, Business
                                  Analytics, and a PGDBM from Birla Institute of
                                  Management Technology.{" "}
                                </p>
                                <p>
                                  For over 13 years she worked in Banks like
                                  HSBC, Kotak, and then in media like BigFM. She
                                  was successful but couldn’t find fulfillment
                                  in corporate life and quit her high-paying job
                                  to do Mar-Com for a group of tribal ladies
                                  making handicrafts in rural West Bengal.{" "}
                                </p>
                                <p>
                                  She found her calling while working on this
                                  project. The sheer range and extravagant
                                  beauty of products created by these women
                                  enthralled her. It became a dream for her to
                                  introduce the average Indian to the exquisite
                                  beauty painstakingly hand-produced by these
                                  artisans. It is important to her that these
                                  unknown artists get their due while ensuring
                                  that these offerings be made available at
                                  affordable prices.
                                </p>
                                <p>
                                  Today, Sudeshna is the force behind Ferra
                                  Indica - an offering of eco-friendly products
                                  handmade by the best artisans from across
                                  India. The delightful and eclectic collection
                                  of Accessories, Home décor &amp; Gift Items is
                                  personally is curated keeping in mind the
                                  aesthetics and fashion sense of the urban
                                  population.{" "}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 mt-3 about-content mb-5">
                            <p>
                              Sudeshna’s first love is Mother Nature, and it’s
                              her mission to work towards creating a world that
                              believes in sustainability. She is passionate
                              about saving traditional weaves and indigenous
                              crafting techniques. Due to the popularity of
                              fashion fads, a lot of these art forms have been
                              neglected and are on the verge of disappearing. As
                              a silent crusader, Sudeshna works tirelessly to
                              find crafts, weaves, embroideries from all over
                              India so the average Indian can afford and enjoy
                              the sweet elegance of India’s traditional
                              handlooms and handicrafts.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div class="row row-10">

                               
                                <div class="col-md-4">
                                    <div class="single-banner">
                                        <a href="#"><img width="550" height="260" src="assets/img/banners/banner7.webp" class="img-fluid"
                                                alt="Banner"></a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="single-banner">
                                        <a href="#"><img width="550" height="260" src="assets/img/banners/banner8.webp" class="img-fluid"
                                                alt="Banner"></a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="single-banner">
                                        <a href="#"><img width="550" height="260" src="assets/img/banners/banner9.webp" class="img-fluid"
                                                alt="Banner"></a>
                                    </div>

                                </div>

                            </div> */}

                  {/* Mission, Vission & Goal */}
                  {/*  
                           <div class="about-mission-vission-goal row row-20">

                                <div class="col-lg-4 col-md-4 col-12">
                                    <h3>OUR VISSION</h3>
                                    <p>Ferra Indica
 provide how all this mistaken idea of denouncing pleasure and sing pain was
                                        born an will give you a ete account of the system, and expound the actual teangs
                                        the eat explorer of the truth, the mer of human tas assumenda est, omnis dolor
                                        repellend</p>
                                </div>

                                <div class="col-lg-4 col-md-4 col-12">
                                    <h3>OUR MISSION</h3>
                                    <p>Ferra Indica
 provide how all this mistaken idea of denouncing pleasure and sing pain was
                                        born an will give you a ete account of the system, and expound the actual teangs
                                        the eat explorer of the truth, the mer of human tas assumenda est, omnis dolor
                                        repellend</p>
                                </div>

                                <div class="col-lg-4 col-md-4 col-12">
                                    <h3>OUR GOAL</h3>
                                    <p>Ferra Indica
 provide how all this mistaken idea of denouncing pleasure and sing pain was
                                        born an will give you a ete account of the system, and expound the actual teangs
                                        the eat explorer of the truth, the mer of human tas assumenda est, omnis dolor
                                        repellend</p>
                                </div>

                            </div> */}
                </div>
              </div>
              {/* =======  End of page wrapper  ======= */}
            </div>
          </div>
        </div>
      </div>

      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {AddLibrary("/assets/js/vendors.js")}
      {AddLibrary("/assets/js/active.js")}
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default About;
