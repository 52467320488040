import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SERVER_URL } from "../config";
import NewsLater from "../components/NewsLater";
import Breadcrumb from "../components/Breadcrumb";
import Header from "../layouts/Header";
import { Link, useParams } from "react-router-dom";
import { CategorySkeletonLoader } from "../components/CategorySkeletonLoader";
import ProductSkeletonLoader from "../components/ProductSkeletonLoader";
import MyHelmat from "../components/MyHelmat";
import date from "date-and-time";

// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = false;
  document.body.appendChild(script);
}

const BlogPostByCategory = () => {
  const { slug = null } = useParams();
  const [blogCategories, setBlogCategories] = useState([]);
  const [categoryLoading, setCalerogyLoading] = useState(true);

  const [recentPostLoading, setRecentPostLoading] = useState(true);
  const [recentPosts, setRecentPosts] = useState([]);

  const [blogPostsLoading, setBlogPostsLoading] = useState(true);
  const [blogPosts, setblogPosts] = useState([]);

  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 6,
    totalRecord: 0,
    totalPage: 0,
    currentPage: 1,
  });

  // Get Blog Categories
  useEffect(() => {
    setCalerogyLoading(true);
    fetch(`${SERVER_URL}/categories?limit=0`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCalerogyLoading(false);
        if (data.status == 200) {
          setBlogCategories(data.body || []);
        } else {
          toast.error(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setCalerogyLoading(false);
        toast.warning(error);
      });
  }, []);

  // Recent Post
  useEffect(() => {
    setRecentPostLoading(true);
    fetch(`${SERVER_URL}/blogPosts?limit=5`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRecentPostLoading(false);
        if (data.status == 200) {
          setRecentPosts(data.body || []);
        } else {
          toast.error(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setRecentPostLoading(false);
        toast.warning(error);
      });
  }, []);

  // Get Blog Post
  useEffect(() => {
    setBlogPostsLoading(true);
    fetch(
      `${SERVER_URL}/blogPosts?limit=${pagination.limit}&skip=${pagination.skip}&categorySlug=${slug}`,
      {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setBlogPostsLoading(false);
        if (data.status == 200) {
          setblogPosts(data.body);
        } else {
          const keys = Object.keys(data.errors);
          keys.forEach((key) => {
            toast.error(data.errors[key]);
          });
          toast.error(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setBlogPostsLoading(false);
        toast.warning(error);
      });
  }, [pagination, slug]);

  // Total Products
  useEffect(() => {
    fetch(`${SERVER_URL}/blogPosts?limit=0&categorySlug=${slug}`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setPagination({
            ...pagination,
            totalRecord: data.body.length,

            totalPage: Math.ceil(data.body.length / pagination.limit),
          });
        } else {
          toast.error(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        toast.warning(error);
      });
  }, [slug]);

  const pageHandler = (e, page) => {
    e.preventDefault();
    setPagination({
      ...pagination,
      skip: page == 1 ? 0 : (page - 1) * pagination.limit,
      currentPage: page,
    });
  };

  const previousPageHandler = (e) => {
    e.preventDefault();

    setPagination({
      ...pagination,
      currentPage: pagination.currentPage == 1 ? 1 : pagination.currentPage - 1,
      skip:
        pagination.currentPage == 1
          ? 0
          : (pagination.currentPage - 2) * pagination.limit,
    });
  };

  const nextPageHandler = (e) => {
    e.preventDefault();

    setPagination({
      ...pagination,
      currentPage:
        pagination.currentPage == pagination.totalPage
          ? pagination.totalPage
          : pagination.currentPage + 1,
      skip:
        pagination.currentPage == pagination.totalPage
          ? 0
          : pagination.currentPage * pagination.limit,
    });
  };

  // Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  return (
    <>
      <Header />
      <MyHelmat title={`Ferra Indica Blog > ${slug.toUpperCase()}`} />
      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb title={slug.toUpperCase()} page={`cat > ${slug}`} />

      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div class="page-content-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div class="page-wrapper">
                <div class="page-content-wrapper">
                  <div class="row">
                    <div class="col-lg-3 order-2">
                      {/*=======  page sidebar wrapper =======*/}
                      <div class="page-sidebar-wrapper">
                        {/*=======  single sidebar widget  =======*/}
                        <div class="single-sidebar-widget">
                          <h4 class="single-sidebar-widget__title">
                            Categories
                          </h4>
                          <ul class="single-sidebar-widget__category-list">
                            {categoryLoading
                              ? [...Array(5)].map((_, i) => {
                                  return <CategorySkeletonLoader key={i} />;
                                })
                              : blogCategories.map((category, index) => {
                                  return (
                                    <li>
                                      <Link to={`/blog/cat/${category.slug}`}>
                                        {category.name}
                                        <span class="counter">
                                          {category.posts}
                                        </span>
                                      </Link>
                                    </li>
                                  );
                                })}
                          </ul>
                        </div>
                        {/*=======  End of single sidebar widget  =======*/}

                        {/*=======  single sidebar widget  =======*/}
                        <div class="single-sidebar-widget">
                          <h4 class="single-sidebar-widget__title">
                            Recent Posts
                          </h4>

                          {/*=======  block container  =======*/}

                          <div class="block-container">
                            {/*=======  single block  =======*/}

                            {recentPostLoading
                              ? [...Array(5)].map((_, i) => {
                                  return (
                                    <CategorySkeletonLoader
                                      height={80}
                                      key={i}
                                    />
                                  );
                                })
                              : recentPosts.map((recentPost, index) => {
                                  return (
                                    <div class="single-block d-flex">
                                      <div class="image">
                                        <Link to={`/blog/${recentPost.slug}`}>
                                          <img
                                            width="370"
                                            height="235"
                                            src={recentPost.defaultImage}
                                            class="img-fluid"
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                      <div class="content">
                                        <p>
                                          <Link to={`/blog/${recentPost.slug}`}>
                                            {recentPost.title}
                                          </Link>{" "}
                                          <span>
                                            {date.format(
                                              date.parse(
                                                recentPost.createdAt,
                                                "DD-MM-YYYY"
                                              ),
                                              "DD-MMM-YYYY"
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}

                            {/*=======  End of single block  =======*/}

                            {/*=======  End of single block  =======*/}
                          </div>

                          {/*=======  End of block container  =======*/}
                        </div>
                        {/*=======  End of single sidebar widget  =======*/}

                        {/*=======  single sidebar widget  =======*/}

                        {/*=======  End of single sidebar widget  =======*/}

                        {/*=======  single sidebar widget  =======*/}
                        {/* <div class="single-sidebar-widget">
                          <h4 class="single-sidebar-widget__title">
                            Popular Tags
                          </h4>
                          <ul class="single-sidebar-widget__tag-list">
                            <li>
                              <a href="#">Car Seats</a>
                            </li>
                            <li>
                              <a href="#">Chair</a>
                            </li>
                            <li>
                              <a href="#">Engine Parts</a>
                            </li>
                            <li>
                              <a href="#">Sofas</a>
                            </li>
                            <li>
                              <a href="#">Sofas</a>
                            </li>
                            <li>
                              <a href="#">Decor</a>
                            </li>
                            <li>
                              <a href="#">Furniture</a>
                            </li>
                            <li>
                              <a href="#">Chair</a>
                            </li>
                          </ul>
                        </div> */}
                        {/*=======  End of single sidebar widget  =======*/}
                      </div>
                      {/*=======  End of page sidebar wrapper  =======*/}
                    </div>

                    <div class="col-lg-9 order-1">
                      {/*=======  blog page content  =======*/}
                      <div class="blog-page-content">
                        <div class="row">
                          {blogPostsLoading ? (
                            [...Array(4)].map((_, $) => {
                              return (
                                <div className="col-md-6">
                                  <ProductSkeletonLoader key={`post-${$}`} />
                                </div>
                              );
                            })
                          ) : blogPosts.length ? (
                            blogPosts.map((post, index) => {
                              return (
                                <div class="col-md-6">
                                  {/*=======  single blog post  =======*/}
                                  <div class="single-blog-post">
                                    <div class="single-blog-post__image">
                                      <Link to={`/blog/${post.slug}`}>
                                        {post.defaultImage ? (
                                          <img
                                            width="370"
                                            height="235"
                                            src={post.defaultImage}
                                            class="img-fluid"
                                            alt=""
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </Link>
                                    </div>
                                    <div class="single-blog-post__content">
                                      <h3 class="title">
                                        <Link to={`/blog/${post.slug}`}>
                                          {post.name}
                                        </Link>
                                      </h3>
                                      <p class="post-meta">
                                        {" "}
                                        <span class="date">
                                          {date.format(
                                            date.parse(
                                              post.createdAt,
                                              "DD-MM-YYYY"
                                            ),
                                            "DD-MMM-YYYY"
                                          )}
                                        </span>
                                        <span class="separator">|</span> by{" "}
                                        <Link to="#">{post.author.name}</Link>
                                      </p>
                                      <p class="short-desc">
                                        <Link to={`/blog/${post.slug}`}>
                                          <h4>{post.title}</h4>
                                        </Link>
                                      </p>
                                      <Link
                                        to={`/blog/${post.slug}`}
                                        class="blog-post-link"
                                      >
                                        Read More
                                      </Link>
                                    </div>
                                  </div>
                                  {/*=======  End of single blog post  =======*/}
                                </div>
                              );
                            })
                          ) : (
                            <div className="alert alert-danger">
                              No Post Available
                            </div>
                          )}
                        </div>
                      </div>

                      {/*=======  pagination area =======*/}
                      <div class="pagination-area">
                        <div class="pagination-area__left">
                          Showing {pagination.skip + 1} to{" "}
                          {blogPosts.length + pagination.skip} of{" "}
                          {pagination.totalRecord} ({pagination.totalPage}{" "}
                          Pages)
                        </div>
                        <div class="pagination-area__right">
                          <ul className="pagination-section">
                            {pagination.currentPage == 1 ? (
                              <li>
                                <span className="Previous text-muted" href="#">
                                  Previous
                                </span>
                              </li>
                            ) : (
                              <li>
                                <a
                                  onClick={previousPageHandler}
                                  className="Previous"
                                  href="#"
                                >
                                  Previous
                                </a>
                              </li>
                            )}
                            {[...Array(pagination.totalPage)].map((_, i) => {
                              return (
                                <li>
                                  <a
                                    href="#"
                                    onClick={(e) => pageHandler(e, i + 1)}
                                  >
                                    {i + 1}
                                  </a>
                                </li>
                              );
                            })}

                            {pagination.currentPage == pagination.totalPage ? (
                              <li>
                                <span className="Next text-muted" href="#">
                                  Next
                                </span>
                              </li>
                            ) : (
                              <li>
                                <a
                                  onClick={nextPageHandler}
                                  className="Next"
                                  href="#"
                                >
                                  Next
                                </a>
                              </li>
                            )}
                          </ul>
                          {/* <ul class="pagination-section">
                            <li>
                              <a class="active" href="#">
                                1
                              </a>
                            </li>
                            <li>
                              <a href="#">2</a>
                            </li>
                            <li>
                              <a href="#">{">"}</a>
                            </li>
                            <li>
                              <a href="#">{">"}|</a>
                            </li>
                          </ul> */}
                        </div>
                      </div>
                      {/*=======  End of pagination area  =======*/}
                      {/*=======  End of blog page content  =======*/}
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {AddLibrary("/assets/js/vendors.js")}
      {AddLibrary("/assets/js/active.js")}
    </>
  );
};

export default BlogPostByCategory;
