import React from "react";

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slider-arrow slider-arrow-2 carausel-4-columns-arrow`}
      id="carausel-4-columns-2-arrows"
      style={{ ...style }}
      onClick={onClick}
    >
      {/* <span className="slider-btn slider-next slick-arrow">
          <i className="fa fa-angle-right"></i>
        </span> */}
      <span className="">
        <i className="fa fa-angle-right"></i>
      </span>
    </div>
  );
}

export default SamplePrevArrow;
