import React from "react";
import Loader from "./Loader";

const AccountDetailsTab = ({
  updateProfileHandler,
  profile,
  setProfile,
  updateProfileLoading,
}) => {
  return (
    <>
      <h3>Account Details</h3>

      <div className="account-details-form">
        <form action="#" onSubmit={updateProfileHandler}>
          <div className="row">
            <div className="col-lg-6 col-12">
              <input
                id="first-name"
                value={profile.firstName}
                onChange={(evt) => {
                  setProfile({
                    ...profile,
                    firstName: evt.target.value,
                  });
                }}
                placeholder="First Name"
                type="text"
              />
            </div>

            <div className="col-lg-6 col-12">
              <input
                id="last-name"
                value={profile.lastName}
                onChange={(evt) => {
                  setProfile({
                    ...profile,
                    lastName: evt.target.value,
                  });
                }}
                placeholder="Last Name"
                type="text"
              />
            </div>

            {/* <div className="col-12">
        <input
          id="display-name"
          placeholder="Display Name"
          type="text"
        />
      </div> */}

            <div className="col-12">
              <input
                id="email"
                value={profile.email}
                onChange={(evt) => {
                  setProfile({
                    ...profile,
                    email: evt.target.value,
                  });
                }}
                placeholder="Email Address"
                type="email"
              />
            </div>

            <div className="col-12 mb-2">
              <h4>Password change</h4>
            </div>

            {/* <div className="col-12">
        <input
          id="current-pwd"
          onChange={(evt) => {
            setProfile({
              ...profile,
              oPassword: evt.target.value,
            });
          }}
          value={profile.oPassword}
          placeholder="Current Password"
          type="password"
        />
      </div> */}

            <div className="col-lg-6 col-12">
              <input
                id="new-pwd"
                onChange={(evt) => {
                  setProfile({
                    ...profile,
                    password: evt.target.value,
                  });
                }}
                value={profile.password}
                placeholder="New Password"
                type="password"
              />
            </div>

            <div className="col-lg-6 col-12">
              <input
                id="confirm-pwd"
                placeholder="Confirm Password"
                onChange={(evt) => {
                  setProfile({
                    ...profile,
                    cPassword: evt.target.value,
                  });
                }}
                value={profile.cPassword}
                type="password"
              />
            </div>

            <div className="col-12">
              <button
                className="save-change-btn"
                disabled={updateProfileLoading ? true : false}
              >
                {updateProfileLoading ? <Loader /> : "Save Changes"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AccountDetailsTab;
