import React, { useEffect, useContext, useState } from "react";

import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SERVER_URL } from "../config";
import { CustomerContext } from "../Routes";
import date from "date-and-time";

import NewsLater from "../components/NewsLater";
import Header from "../layouts/Header";

const MyAccount = () => {
  const { id } = useParams();
  const { state, dispatch } = useContext(CustomerContext);
  const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
  const history = useHistory();
  const [profile, setProfile] = useState({});
  const [billingAddress, setBillingAddress] = useState({});
  const [shippingAddress, setShippingAddress] = useState([]);
  const [orderDetails, setOrderDetails] = useState({
    products: [],
    adonProducts: [],
    createdAt: "",
    updatedAt: "",
  });
  const [isReturnable, setIsReturnable] = useState(false);

  const [customer, setCustomer] = useState({
    name: "",
    mobile: "",
    address_1: "",
    address_2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    password: "",
  });

  const [profileLoading, setProfileLoading] = useState(false);
  const [allWishlistLoading, setAllWishlistLoading] = useState(false);
  const [removeFromWishlist, setRemoveFromWishlist] = useState(false);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(false);

  const [needAddressLoading, setNeedAddressLoading] = useState(false);

  useEffect(() => {
    if (!customerInfo) {
      history.push("/account/login");
    }

    if (customerInfo && !customerInfo.jwtToken) {
      history.push("/account/login");
    }
  }, []);

  // Get Profile
  useEffect(() => {
    setProfileLoading(true);
    fetch(`${SERVER_URL}/customers/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setProfileLoading(false);
          if (result.status == 200) {
            setCustomer({
              ...result.body,
            });
            setProfile({
              firstName: result.body.firstName,
              lastName: result.body.lastName,
              mobile: result.body.mobile,
              email: result.body.email,
            });
            setShippingAddress(result.body.shippingAddresses || []);
          } else {
            toast.error(result.message);
            console.log(result);
          }
        },
        (error) => {
          setProfileLoading(false);
          toast.error(error);
          console.log(error);
        }
      );
  }, [needAddressLoading]);

  // Logout Function
  const logout = (evt) => {
    evt.preventDefault();
    localStorage.removeItem("customerInfo");
    dispatch({ type: "CLEAR" });
    toast.success("Successfully Logout !!");
    history.push("/account/login");
  };

  // Get Orders
  useEffect(() => {
    setAllWishlistLoading(true);
    fetch(`${SERVER_URL}/orders/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setAllWishlistLoading(false);
          if (result.status == 200) {
            // console.log(result.body);
            setOrderDetails(result.body || []);

            const today = date.format(
              new Date().setDate(
                Number(new Date().getDate()) +
                  Number(result.body.returnCondition)
              ),
              "YYYY-MM-DD"
            );

            const deliveryDate = date.format(
              date.parse(result.body.updatedAt, "DD-MM-YYYY"),
              "YYYY-MM-DD"
            );

            console.log(today, deliveryDate);
            // console.log(result.body);
          } else {
            toast.error(result.message);
            // console.log(result);
          }
        },
        (error) => {
          setAllWishlistLoading(false);
          toast.error(error);
          // console.log(error);
        }
      );
  }, [removeFromWishlist]);

  const updateProfileHandler = (evt) => {
    const updateData = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      password: profile.password || undefined,
      cPassword: profile.cPassword || undefined,
      billingAddress:
        JSON.stringify(billingAddress) == "{}" ? undefined : billingAddress,
    };

    evt.preventDefault();
    setUpdateProfileLoading(true);
    fetch(`${SERVER_URL}/customers/profile`, {
      method: "PUT",
      body: JSON.stringify(updateData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setUpdateProfileLoading(false);

          if (result.status == 200) {
            setProfile({
              firstName: result.body.firstName,
              lastName: result.body.lastName,
              mobile: result.body.mobile,
              email: result.body.email,
              password: "",
              cPassword: "",
            });
            toast.success(result.message);
          } else {
            const errors = result.errors;
            const keys = Object.keys(errors);
            for (let k of keys) {
              toast.error(errors[k]);
            }
            // console.log(result);
          }
        },
        (error) => {
          setUpdateProfileLoading(false);
          toast.error(error);
          console.log(error);
        }
      );
  };

  return (
    <>
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area section-space--half">
        <div className="container wide">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  breadcrumb wrpapper  =======*/}
              <div className="breadcrumb-wrapper breadcrumb-bg">
                {/*=======  breadcrumb content  =======*/}
                <div className="breadcrumb-content">
                  <h2 className="breadcrumb-content__title">My Account</h2>
                  <ul className="breadcrumb-content__page-map">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li className="active">My Account</li>
                  </ul>
                </div>
                {/*=======  End of breadcrumb content  =======*/}
              </div>
              {/*=======  End of breadcrumb wrpapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div className="page-content-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div className="page-wrapper">
                <div className="page-content-wrapper">
                  <div className="row">
                    {/* My Account Tab Menu Start */}
                    <div className="col-lg-12 m-auto">
                      <div className="row">
                        <div className="col-md-9">
                          <div className="card bg-white">
                            <div className="card-header d-flex justify-content-between bg-white">
                              <h3>
                                Order Details
                                <span
                                  className="badge bg-success"
                                  style={{ fontSize: "12px" }}
                                >
                                  (Order Id : {orderDetails._id})
                                </span>
                              </h3>
                              <h4>
                                Order Amount{" "}
                                <span className="fa fa-inr">
                                  {orderDetails.totalAmount}
                                </span>
                              </h4>
                            </div>
                            <div className="card-body">
                              <div class="container">
                                {/* Not cancelled and Not Returned */}
                                {orderDetails.orderStatus != "CANCELLED" &&
                                orderDetails.orderStatus != "RETURNED" ? (
                                  <div id="stepProgressBar">
                                    <div class="step">
                                      <p class="step-text">ORDERPLACED</p>
                                      <div
                                        class={`bullet line ${
                                          orderDetails.orderStatus ==
                                            "ORDERPLACED" ||
                                          orderDetails.orderStatus ==
                                            "CONFIRMED" ||
                                          orderDetails.orderStatus ==
                                            "DISPATCHED" ||
                                          orderDetails.orderStatus ==
                                            "DELIVERED"
                                            ? "completed"
                                            : ""
                                        }`}
                                      >
                                        1
                                      </div>
                                    </div>
                                    <div class="step">
                                      <p class="step-text">CONFIRMED</p>
                                      <div
                                        class={`bullet line ${
                                          orderDetails.orderStatus ==
                                            "CONFIRMED" ||
                                          orderDetails.orderStatus ==
                                            "DISPATCHED" ||
                                          orderDetails.orderStatus ==
                                            "DELIVERED"
                                            ? "completed"
                                            : ""
                                        }`}
                                      >
                                        2
                                      </div>
                                    </div>
                                    <div class="step">
                                      <p class="step-text">DISPATCHED</p>
                                      <div
                                        class={`bullet line ${
                                          orderDetails.orderStatus ==
                                            "DISPATCHED" ||
                                          orderDetails.orderStatus ==
                                            "DELIVERED"
                                            ? "completed"
                                            : ""
                                        }`}
                                      >
                                        2
                                      </div>
                                    </div>
                                    <div class="step">
                                      <p class="step-text">DELIVERED</p>
                                      <div
                                        class={`bullet ${
                                          orderDetails.orderStatus ==
                                          "DELIVERED"
                                            ? "completed"
                                            : ""
                                        }`}
                                      >
                                        3
                                      </div>
                                    </div>
                                  </div>
                                ) : orderDetails.orderStatus == "CANCELLED" ? (
                                  <div className="alert alert-danger">
                                    This Order has beet Cancelled by{" "}
                                    {orderDetails.cancelledBy}
                                    <br />
                                    Reason: {orderDetails.cancelMessage}
                                  </div>
                                ) : (
                                  <div className="alert alert-danger">
                                    This Order has beet Returned
                                  </div>
                                )}

                                <div className="row">
                                  {/* Product Details */}
                                  <div className="col-md-12">
                                    <div className="pb-4">
                                      <h4>Product Details</h4>
                                    </div>
                                    {orderDetails.products.map(
                                      (item, index) => {
                                        return (
                                          <div
                                            key={`product-${index}`}
                                            className="d-flex justify-content-between"
                                          >
                                            <div className="d-flex justify-content-between">
                                              <div className="">
                                                <Link
                                                  to={`/product/${item.slug}`}
                                                >
                                                  <img
                                                    style={{
                                                      height: "80px",
                                                      width: "80px",
                                                      borderRadius: "20px",
                                                    }}
                                                    src={item.image}
                                                    alt="Product Image"
                                                  />
                                                </Link>
                                              </div>
                                              <div className="px-2 py-3">
                                                <h4>
                                                  <Link
                                                    to={`/product/${item.slug}`}
                                                  >
                                                    {item.name}
                                                  </Link>
                                                </h4>
                                                <h4 className="text-small">
                                                  {item.color ? item.color : ""}{" "}
                                                  |
                                                  {item.material
                                                    ? item.material.name
                                                    : ""}
                                                  |{" "}
                                                  {item.brand
                                                    ? item.brand.name
                                                    : ""}
                                                </h4>
                                              </div>
                                            </div>
                                            <div className="">
                                              <div className="">
                                                <h4>
                                                  {" "}
                                                  <i className="fa fa-inr"></i>{" "}
                                                  {item.price}{" "}
                                                </h4>
                                                <h5 className="text-small">
                                                  Qty : {item.quantity}
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>

                                  {/* Cancel & Return Order */}
                                  <div className="py-4 d-flex ">
                                    <div className="">
                                      {orderDetails.orderStatus ==
                                        "ORDERPLACED" ||
                                      orderDetails.orderStatus ==
                                        "CONFIRMED" ? (
                                        <Link
                                          to={`/account/cancelOrder/${orderDetails._id}`}
                                          className="btn btn-danger"
                                        >
                                          Cancel Order
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="px-2">
                                      {isReturnable ? (
                                        <Link className="btn btn-danger">
                                          Return Order
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  {/* Table Design */}
                                  <table className="table table-striped table-bordered order-table mt-3 h6">
                                    <tr>
                                      <th>Delivery Charges</th>
                                      <td className="text-right">
                                        <i className="fa fa-inr"></i>
                                        {orderDetails.deliveryAmount | 0}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Subtotal</th>
                                      <td className="text-right">
                                        <i className="fa fa-inr"></i>
                                        {orderDetails.subtotal}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Discount with Coupon</th>
                                      <td className="text-right">
                                        <i className="fa fa-inr"></i>
                                        {orderDetails.discountWithCoupon || 0}
                                      </td>
                                    </tr>

                                    {orderDetails?.specialDiscountAmount ? (
                                      <tr>
                                        <th>
                                          Special Dicsount{" "}
                                          {
                                            orderDetails?.specialDiscount
                                              ?.discount
                                          }
                                          <span style={{ fontSize: "12px" }}>
                                            (
                                            {
                                              orderDetails?.specialDiscount
                                                ?.message
                                            }
                                            )
                                          </span>
                                        </th>
                                        <td className="text-right">
                                          <i className="fa fa-inr"></i>
                                          {orderDetails.specialDiscountAmount ||
                                            0}
                                        </td>
                                      </tr>
                                    ) : null}

                                    <tr>
                                      <th>Total Amount</th>
                                      <td>
                                        <i className="fa fa-inr"></i>
                                        {orderDetails.totalAmount}
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 ">
                          <div className="card bg-white mb-3">
                            <div className="card-body">
                              <h4>Payment Details</h4>
                              <h5>
                                Method :{" "}
                                <span className="badge bg-success">
                                  {orderDetails.paymentMethod}
                                </span>{" "}
                              </h5>
                              {orderDetails.paymentMethod == "ONLINE" ? (
                                <h5>
                                  Payment Id: {orderDetails.razorpayPaymentId}
                                </h5>
                              ) : (
                                ""
                              )}
                              <h5></h5>
                            </div>
                          </div>

                          <div className="card bg-white mb-3">
                            <div className="card-body">
                              <h4 className="pb-3">Shipping Address</h4>
                              {orderDetails.shippingAddress ? (
                                <div className="">
                                  <h5 className="pb-0">
                                    Name: {orderDetails.shippingAddress.name}{" "}
                                  </h5>
                                  <h5 className="pb-0">
                                    Email: {orderDetails.shippingAddress.email}{" "}
                                  </h5>
                                  <h5 className="pb-0">
                                    Mobile:{" "}
                                    {orderDetails.shippingAddress.mobile}{" "}
                                  </h5>
                                  <h5 className="pb-0">
                                    Address:{" "}
                                    {orderDetails.shippingAddress.address}{" "}
                                  </h5>
                                  <h5 className="pb-0">
                                    Landmark:{" "}
                                    {orderDetails.shippingAddress.landmark}{" "}
                                  </h5>
                                  <h5 className="pb-0">
                                    City: {orderDetails.shippingAddress.city} ,{" "}
                                    {orderDetails.shippingAddress.pincode}{" "}
                                  </h5>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="card bg-white mb-3">
                            <div className="card-body">
                              <h4 className="pb-3">Order History</h4>

                              <div className="">
                                <h5 className="pb-2">
                                  Order ID :{" "}
                                  <span className="badge bg-success">
                                    {orderDetails._id}
                                  </span>
                                </h5>
                                <h5 className="pb-2">
                                  Order Date :{" "}
                                  {date.format(
                                    date.parse(
                                      orderDetails.createdAt,
                                      "DD-MM-YYYY"
                                    ),
                                    "DD-MMM-YYYY"
                                  )}{" "}
                                </h5>
                                <h5 className="pb-2">
                                  {orderDetails.orderStatus == "DELIVERED" ? (
                                    <span>
                                      Delivered Date :{" "}
                                      {date.format(
                                        date.parse(
                                          orderDetails.updatedAt,
                                          "DD-MM-YYYY"
                                        ),
                                        "DD-MMM-YYYY"
                                      )}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default MyAccount;
