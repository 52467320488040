import React, { createContext, useReducer, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { initialState, customerReducer } from "./reducer/CustomerReducer";
import Login from "./pages/Login";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
// import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import ProductDetails from "./pages/ProductDetails";
import Listing from "./pages/Listing";
import Register from "./pages/Register";
import MyAccount from "./pages/MyAccount";
import OtpVerifiction from "./pages/OtpVerifiction";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import GuestCheckout from "./pages/GuestCheckout";
import Wishlists from "./pages/Wishlists";
import OrderDetails from "./pages/OrderDetails";
import CancelOrder from "./pages/CancelOrder";
import BlogPost from "./pages/BlogPost";
import Blog from "./pages/Blog";
import BlogPostByCategory from "./pages/BlogPostByCategory";
import SearchedItems from "./pages/SearchedItems";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import ShippingPolicies from "./pages/ShippingPolicies";
import ReturnRefundPolicies from "./pages/ReturnRefundPolicies";
import Offers from "./components/Offers";
import ForgotPassword from "./pages/ForgotPassword";
import EnterOtp from "./pages/EnterOtp";
import CreateNewPassword from "./pages/CreateNewPassword";
import ThankYou from "./pages/ThankYou";
import { ToastContainer } from "react-toastify";

// Create Context
export const CustomerContext = createContext();

const Routing = () => {
  return (
    <Switch>
      <Route exact path={"/"} component={Home} />

      <Route exact path={"/about"} component={About} />
      <Route exact path={"/cart"} component={Cart} />
      <Route exact path={"/checkout"} component={Checkout} />
      <Route exact path={"/guest-checkout"} component={GuestCheckout} />
      <Route exact path={"/wishlists"} component={Wishlists} />
      <Route exact path={"/contact"} component={Contact} />
      <Route exact path={"/product/:slug"} component={ProductDetails} />
      <Route exact path={"/account/login"} component={Login} />
      <Route exact path={"/forgot-password"} component={ForgotPassword} />
      <Route exact path={"/enter-otp"} component={EnterOtp} />
      <Route exact path={"/create-password"} component={CreateNewPassword} />

      <Route exact path={"/account/register"} component={Login} />
      <Route exact path={"/privacy-policy"} component={PrivacyPolicy} />
      <Route exact path={"/terms-and-conditions"} component={TermsConditions} />
      <Route exact path={"/shipping-policies"} component={ShippingPolicies} />
      <Route exact path={"/offers"} component={Offers} />
      <Route exact path={"/thank-you"} component={ThankYou} />
      <Route
        exact
        path={"/return-refund-policies"}
        component={ReturnRefundPolicies}
      />
      <Route
        exact
        path={"/account/orderDetails/:id"}
        component={OrderDetails}
      />
      <Route exact path={"/account/cancelOrder/:id"} component={CancelOrder} />

      {/* Searched Item */}
      <Route exact path={"/search"} component={SearchedItems} />

      {/* Blog Post */}
      <Route exact path={"/blog"} component={Blog} />
      <Route exact path={"/blog/cat/:slug?"} component={BlogPostByCategory} />
      <Route exact path={"/blog/:slug?"} component={BlogPost} />

      {/* Forget Password End */}

      <Route exact path={"/account/register"} component={Register} />
      <Route exact path={"/account/myAccount/:slug?"} component={MyAccount} />
      <Route
        exact
        path={"/account/otpVerification"}
        component={OtpVerifiction}
      />
      <Route
        exact
        path={"/:parCatSlug/:subCatSlug?/:childCatSlug?"}
        component={Listing}
      />
      <Route exact path={"/*"} component={ProductDetails} />
    </Switch>
  );
};

const Routes = () => {
  const [state, dispatch] = useReducer(customerReducer, initialState);

  return (
    <div id="main-wrapper">
      {/* <ToastContainer /> */}
      <CustomerContext.Provider value={{ state: state, dispatch: dispatch }}>
        <Router>
          {/* <Header /> */}
          <Routing />
          <Footer />
        </Router>
      </CustomerContext.Provider>
    </div>
  );
};

export default Routes;
