import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../config";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomerContext } from "../Routes";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";

import { gapi } from "gapi-script";
import NewsLater from "../components/NewsLater";
import Breadcrumb from "../components/Breadcrumb";
import Loader from "../components/Loader";
import Header from "../layouts/Header";
const clientId =
  "1041146774583-eogmiftqgbekc7r62uocdrm5gk7uumco.apps.googleusercontent.com";

const Login = () => {
  const history = useHistory();
  const loginScrollRef = useRef(null);
  const [loginData, setLoginData] = useState({
    mobile: "",
    password: "",
  });

  const [regData, setRegData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    cPassword: "",
  });

  const [loginLoading, setLoginLoading] = useState(false);
  const [regLoading, setRegLoading] = useState(false);
  const { state, dispatch } = useContext(CustomerContext);

  const loginHandler = (evt) => {
    setLoginLoading(true);
    evt.preventDefault();

    fetch(`${SERVER_URL}/customers/login`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoginLoading(false);

        if (data.status == 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          // Set data to localstorage
          dispatch({ type: "CUSTOMER", payload: data.body.token });
          localStorage.setItem(
            "customerInfo",
            JSON.stringify({
              ...state,
              jwtToken: data.body.token,
            })
          );

          history.push("/account/myAccount");
        } else if (data.status == 401) {
          toast.success(data.message);
          localStorage.setItem(
            "user",
            JSON.stringify({
              otp: data.body.otp,
              email: data.body.email,
            })
          );
          history.push("/account/otpVerification");
        } else if (data.status == 403) {
          toast.error(data.message, {
            position: toast.POSITION_TOP_RIGHT,
          });
        } else {
          const keys = Object.keys(data.errors);
          keys.forEach((error) => {
            toast.error(data.errors[error]);
          });

          toast.error(data.message);
        }
      })
      .catch((error) => {
        setLoginLoading(false);
        console.error("Error:", error);
      });
  };

  const registrationHandler = (evt) => {
    setRegLoading(true);
    evt.preventDefault();

    fetch(`${SERVER_URL}/customers/register`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(regData),
    })
      .then((response) => response.json())
      .then((data) => {
        setRegLoading(false);
        if (data.status == 200 || data.status == 401) {
          // Set data to localstorage
          dispatch({ type: "CUSTOMER", payload: data.body.token });
          localStorage.setItem(
            "customerInfo",
            JSON.stringify({
              ...state,
              jwtToken: data.body.token,
            })
          );

          history.push("/account/myAccount");

          // toast.success(data.message);
          // localStorage.setItem(
          //   "otpVerification",
          //   JSON.stringify({
          //     otp: Number.parseInt(data.body.otp) * 2,
          //     mobile: data.body.mobile,
          //     firstName: data.body.firstName,
          //   })
          // );
          // history.push("/account/otpVerification");
        } else if (data.status == 302) {
          toast.error(data.message, {
            position: toast.POSITION_TOP_RIGHT,
          });
          if (loginScrollRef.current) {
            loginScrollRef.current.scrollIntoView();
          }
        } else {
          const keys = Object.keys(data.errors);
          keys.forEach((error) => {
            toast.error(data.errors[error]);
          });

          toast.error(data.message);
        }
      })
      .catch((error) => {
        setRegLoading(false);
        console.error("Error:", error);
      });
  };

  const responseFacebook = (response) => {
    const { name, email } = response;

    fetch(`${SERVER_URL}/customers/socialRegistration`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, name, is_verified: true }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          // Set data to localstorage
          dispatch({ type: "CUSTOMER", payload: data.body.token });
          localStorage.setItem(
            "customerInfo",
            JSON.stringify({
              ...state,
              jwtToken: data.body.token,
            })
          );

          history.push("/account/myAccount");
        } else {
          // setFormErrors(data.errors);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const responseGoogle = (response) => {
    if (response.profileObj) {
      const { email, name, imageUrl } = response.profileObj;

      fetch(`${SERVER_URL}/customers/socialRegistration`, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          name,
          image_url: imageUrl,
          is_verified: true,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            // Set data to localstorage
            dispatch({ type: "CUSTOMER", payload: data.body.token });
            localStorage.setItem(
              "customerInfo",
              JSON.stringify({
                ...state,
                jwtToken: data.body.token,
              })
            );

            history.push("/account/myAccount");
          } else {
            // setFormErrors(data.errors);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  // Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb title="Login - Register" />
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div className="page-content-area" ref={loginScrollRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div className="page-wrapper">
                <div className="page-content-wrapper">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-6">
                      {/* Login Form s*/}
                      <form action="#" onSubmit={loginHandler}>
                        <div className="login-form">
                          <h4 className="login-title">Login</h4>

                          <div className="row">
                            <div className="col-md-12 col-12">
                              <label>Mobile Number*</label>
                              <input
                                type="tel"
                                value={loginData.mobile}
                                placeholder="Mobile Number"
                                onChange={(evt) => {
                                  setLoginData({
                                    ...loginData,
                                    mobile: evt.target.value,
                                  });
                                }}
                              />
                            </div>

                            <div className="col-12">
                              <label>Password</label>
                              <input
                                type="password"
                                value={loginData.password}
                                placeholder="Password"
                                onChange={(evt) => {
                                  setLoginData({
                                    ...loginData,
                                    password: evt.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-sm-6">
                              <div className="check-box d-inline-block ml-0 ml-md-2">
                                <input type="checkbox" id="remember_me" />
                                <label for="remember_me">Remember me</label>
                              </div>
                            </div>

                            <div className="col-sm-6 text-start text-sm-end">
                              <Link
                                to="/forgot-password"
                                className="forget-pass-link"
                              >
                                {" "}
                                Forgotten pasward?
                              </Link>
                            </div>

                            <div className="col-md-12">
                              <button
                                className="register-button"
                                disabled={loginLoading ? true : false}
                              >
                                {loginLoading ? <Loader /> : "Login"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xs-12">
                      <form action="#" onSubmit={registrationHandler}>
                        <div className="login-form">
                          <h4 className="login-title">Register</h4>

                          <div className="row">
                            <div className="col-md-6 col-12 mb-20">
                              <label>First Name</label>
                              <input
                                type="text"
                                placeholder="First Name"
                                value={regData.firstName}
                                onChange={(evt) => {
                                  setRegData({
                                    ...regData,
                                    firstName: evt.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-md-6 col-12 mb-20">
                              <label>Last Name</label>
                              <input
                                type="text"
                                value={regData.lastName}
                                placeholder="Last Name"
                                onChange={(evt) => {
                                  setRegData({
                                    ...regData,
                                    lastName: evt.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-md-12 mb-20">
                              <label>Email Address</label>
                              <input
                                type="email"
                                value={regData.email}
                                placeholder="Email Address"
                                onChange={(evt) => {
                                  setRegData({
                                    ...regData,
                                    email: evt.target.value,
                                  });
                                }}
                              />
                            </div>

                            <div className="col-md-12 mb-20">
                              <label>Mobile Number*</label>
                              <input
                                type="number"
                                value={regData.mobile}
                                placeholder="Mobile Number"
                                onChange={(evt) => {
                                  setRegData({
                                    ...regData,
                                    mobile: evt.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-md-6 mb-20">
                              <label>Password</label>
                              <input
                                type="password"
                                value={regData.password}
                                placeholder="Password"
                                onChange={(evt) => {
                                  setRegData({
                                    ...regData,
                                    password: evt.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-md-6 mb-20">
                              <label>Confirm Password</label>
                              <input
                                type="password"
                                value={regData.cPassword}
                                placeholder="Confirm Password"
                                onChange={(evt) => {
                                  setRegData({
                                    ...regData,
                                    cPassword: evt.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-12">
                              <button
                                disabled={regLoading ? true : false}
                                type="submit"
                                className="register-button mt-0"
                              >
                                {regLoading ? <Loader /> : "Register"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                      <div className="col-12 login-form pb-5 mt-3">
                        <h4 className="login-title">Continue as a Guest</h4>
                        <Link
                          to={"/guest-checkout"}
                          type="button"
                          className="register-button mt-0 text-center"
                        >
                          Continue
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default Login;
