import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

function SliderImage({ item, key }) {
  const [slider, setSlider] = useState(item);
  const history = useHistory();
  return (
    <div
      onClick={() => {
        history.push(slider.url);
      }}
      key={`slider-${key}`}
      className="single-slider-item"
      style={{ cursor: "pointer" }}
    >
      <div
        className="hero-slider-item-wrapper hero-slider-item-wrapper--fullwidth hero-slider-bg-5"
        style={{ backgroundImage: `url(${slider.image})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero-slider-content hero-slider-content--left-space">
                <p
                  onClick={(evt) => {
                    console.log(slider.title);
                  }}
                  className="slider-title slider-title--big-light"
                  style={{ color: slider.textColor }}
                >
                  {slider.title}
                </p>
                <p
                  className="slider-title slider-title--big-light"
                  style={{ color: slider.textColor }}
                >
                  {slider.subTitle}
                </p>
                <p
                  className="slider-title slider-title--small"
                  style={{ color: slider.textColor }}
                >
                  {slider.text}
                </p>

                {/* <Link
                  className="hero-slider-button"
                  to={slider.url}
                  style={{
                    color: slider.btnTextColor,
                    backgroundColor: slider.btnBgColor,
                  }}
                >
                  {" "}
                  <i className="ion-ios-plus-empty"></i>{" "}
                  {slider.btnText || "SHOP NOW"}
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderImage;
