import React, { useEffect } from "react";
import { useState } from "react";
import { SERVER_URL } from "../../config";
import { toast } from "react-toastify";
import BlogPostCard from "./BlogPostCard";
import Slider from "react-slick";
import ProductSkeletonLoader from "../ProductSkeletonLoader";
import {
  blogPostSliderSetting,
  latestArivalSliderSetting,
} from "../../helpers/slider";

const LatestBlogPostContainer = () => {
  const [blogPostLoading, setBlogPostLoading] = useState(true);
  const [blogPosts, setblogPosts] = useState([]);
  const [postSliderSetting, setPostSliderSetting] = useState({
    ...latestArivalSliderSetting,
  });

  // Get All Blog Post
  useEffect(() => {
    setBlogPostLoading(true);
    fetch(`${SERVER_URL}/blogPosts?skip=0&limit=3`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBlogPostLoading(false);
        if (data.status == 200) {
          setblogPosts(data.body || []);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setBlogPostLoading(false);
        toast.warning(error);
      });
  }, []);
  return (
    <div className="blog-post-slider-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/*-=======  blog post slider border wrapper  =======*/}
            <div className="blog-post-slider-border-wrapper section-space--inner">
              <div className="row">
                <div className="col-lg-12">
                  {/*-=======  section title  =======*/}
                  <div className="section-title-wrapper text-center section-space--half">
                    <h2 className="section-title">Latest Blog Posts</h2>
                    <p className="section-subtitle">
                      Here you can find out latest blog post to getting more
                      understanding about us and about our products.
                    </p>
                  </div>
                  {/*-=======  End of section title  =======*/}
                </div>
                <div className="col-lg-12">
                  {/*-=======  blog post slider wrapper  =======*/}
                  <div
                  // className="blog-post-slider-wrapper"
                  >
                    <div className="row">
                      {blogPosts.map((post, index) => {
                        return (
                          <div className="col-md-4 m-auto">
                            <BlogPostCard
                              post={post}
                              key={`blog-post-${index}`}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/*-=======  End of blog post slider wrapper  =======*/}
                </div>
              </div>
            </div>
            {/*-=======  End of blog post slider border wrapper  =======*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestBlogPostContainer;
