import React from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";

const WishlistsTab = ({ data = [], onRemove, onLoading }) => {
  return (
    <>
      <h3>My Wishlist</h3>

      {data.length ? (
        <div className="myaccount-table table-responsive text-center">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th>No</th>
                <th>Product</th>
                <th>Image</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={`order-${index}`}>
                    <td> {++index} </td>
                    <td>
                      <Link to={`/product/${item.product.slug}`}>
                        {item.product.name}{" "}
                      </Link>
                    </td>
                    <td>
                      <Link to={`/product/${item.product.slug}`}>
                        <img
                          src={item.product.defaultImage}
                          alt=""
                          style={{ height: "80px", width: "80px" }}
                        />
                      </Link>
                    </td>
                    <td>
                      <i className="fa fa-inr"></i>
                      {item.product.sellingPrice}{" "}
                    </td>

                    <td>
                      <Link
                        onClick={(evt) => {
                          onRemove(evt, item._id);
                        }}
                        to={`/product/${item.product.slug}`}
                      >
                        {onLoading ? <Loader /> : "Remove"}
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="alert alert-danger">
          NO PRODUCT AVAILABLE IN WISHLIST
        </div>
      )}
    </>
  );
};

export default WishlistsTab;
