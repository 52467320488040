import React, { useEffect, useRef, useState, useContext } from "react";
import ProductCard from "../components/ProductCard";
import { useParams, Link, useHistory } from "react-router-dom";
import { SERVER_URL } from "../config";
import { toast } from "react-toastify";

import { CustomerContext } from "../Routes";

import Breadcrumb from "../components/Breadcrumb";
import NewsLater from "../components/NewsLater";
import Header from "../layouts/Header";
import Rating from "react-rating";
import MultiRangeSlider from "../components/multiRangeSlider/MultiRangeSlider";
import { CategorySkeletonLoader } from "../components/CategorySkeletonLoader";
import HelmetMetaData from "../components/HelmetMetaData";

// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = false;
  document.body.appendChild(script);
}

const Listing = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(CustomerContext);
  const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
  const {
    parCatSlug = null,
    subCatSlug = null,
    childCatSlug = null,
  } = useParams();

  const [productsLoading, setProductsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [range, setRange] = useState({
    min: 0,
    max: 10000,
  });
  const [sort, setSort] = useState("default");

  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 9,
    totalRecord: 0,
    totalPage: 0,
    currentPage: 1,
  });

  const [parentCategory, setParentCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [childCategory, setChildCategory] = useState(null);

  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [subCategories, setSubCategories] = useState([]);
  const [bannerLoading, setBannerLoading] = useState([]);
  const [breadcrumbBanner, setBreadcrumbBanner] = useState({
    image: "",
    textColor: "",
  });
  const [childCategories, setChildCategories] = useState([]);

  const [colors, setColors] = useState([]);
  const [colorLoading, setColorLoading] = useState(false);

  const [materials, setMaterials] = useState([]);
  const [materialLoading, setMaterialLoading] = useState(false);

  const [sizes, setSizes] = useState([]);
  const [sizeLoading, setSizeLoading] = useState(false);

  const [selectedMaterial, setSelectedMaterial] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [addWishlistLoading, setAddWishlistLoading] = useState(false);
  const [removeWishlistLoading, setRemoveWishlistLoading] = useState(false);
  const [addedToWishlist, setAddedToWishlist] = useState(false);
  const [myWishlists, setMyWishlists] = useState([]);
  const [removeFromWishlist, setRemoveFromWishlist] = useState(false);

  // For Scrolling
  useEffect(() => {
    // ref.current.scrollIntoView({ behavior: "smooth" });
  }, [parCatSlug]);

  // Get Products
  useEffect(() => {
    setProductsLoading(true);
    fetch(
      `${SERVER_URL}/products/filter?parCatSlug=${parCatSlug}&subCatSlug=${subCatSlug}&childCatSlug=${childCatSlug}&limit=${pagination.limit}&skip=${pagination.skip}&maxPrice=${range.max}&minPrice=${range.min}`,
      {
        method: "POST", // or 'PUT'
        body: JSON.stringify({
          materials: selectedMaterial,
          colors: selectedColors,
          sizes: selectedSizes,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setProductsLoading(false);
        if (data.status == 200) {
          let unsortedProducts = [...data.body];
          if (sort == "default") {
          } else if (sort == "name-a-z") {
            unsortedProducts.sort((a, b) => (a.name > b.name ? 1 : -1));
          } else if (sort == "name-z-a") {
            unsortedProducts.sort((a, b) => (a.name < b.name ? 1 : -1));
          } else if (sort == "price-l-h") {
            unsortedProducts.sort((a, b) =>
              a.sellingPrice > b.sellingPrice ? 1 : -1
            );
          } else if (sort == "price-h-l") {
            unsortedProducts.sort((a, b) =>
              a.sellingPrice < b.sellingPrice ? 1 : -1
            );
          }
          setProducts(unsortedProducts);

          // setProducts(data.body || {});
          // sortData();
        } else {
          toast.warning(data.message);
          const keys = Object.keys(data.errors);
          keys.forEach((key) => {
            toast.error(data.errors[key]);
          });
        }
        window.scrollTo(0, 130);
        // console.log("Success:", data);
      })
      .catch((error) => {
        setProductsLoading(false);
        toast.warning(error);
      });
  }, [
    parCatSlug,
    subCatSlug,
    childCatSlug,
    range,
    pagination.skip,
    pagination.limit,
    selectedMaterial,
    selectedColors,
    selectedSizes,
  ]);

  // Total Products
  useEffect(() => {
    fetch(
      `${SERVER_URL}/products/filter?parCatSlug=${parCatSlug}&subCatSlug=${subCatSlug}&childCatSlug=${childCatSlug}&limit=0&skip=0&maxPrice=${range.max}&minPrice=${range.min}`,
      {
        method: "POST", // or 'PUT'
        body: JSON.stringify({
          materials: selectedMaterial,
          colors: selectedColors,
          sizes: selectedSizes,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setPagination({
            ...pagination,
            totalRecord: data.body.length,
            skip: !data.body.length ? 0 : pagination.skip,
            currentPage: !data.body.length ? 1 : pagination.currentPage,
            totalPage: Math.ceil(data.body.length / pagination.limit),
          });
        } else {
          toast.warning(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        toast.warning(error);
      });
  }, [
    parCatSlug,
    subCatSlug,
    range,
    childCatSlug,
    selectedMaterial,
    selectedColors,
    selectedSizes,
  ]);

  // Sort By
  useEffect(() => {
    let unsortedProducts = [...products];
    if (sort == "default") {
    } else if (sort == "name-a-z") {
      unsortedProducts.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sort == "name-z-a") {
      unsortedProducts.sort((a, b) => (a.name < b.name ? 1 : -1));
    } else if (sort == "price-l-h") {
      unsortedProducts.sort((a, b) =>
        a.sellingPrice > b.sellingPrice ? 1 : -1
      );
    } else if (sort == "price-h-l") {
      unsortedProducts.sort((a, b) =>
        a.sellingPrice < b.sellingPrice ? 1 : -1
      );
    }
    setProducts(unsortedProducts);
  }, [sort]);

  // Get Colors
  useEffect(() => {
    setColorLoading(true);
    fetch(`${SERVER_URL}/colors?limit=0`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setColorLoading(false);
        if (data.status == 200) {
          setColors(data.body);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setColorLoading(false);
        toast.warning(error);
      });
  }, []);

  // Get Materials
  useEffect(() => {
    setMaterialLoading(true);
    fetch(`${SERVER_URL}/materials?limit=0`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setMaterialLoading(false);
        if (data.status == 200) {
          setMaterials(data.body);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setMaterialLoading(false);
        toast.warning(error);
      });
  }, []);

  // Get Sizes
  useEffect(() => {
    setSizeLoading(true);
    fetch(`${SERVER_URL}/sizes?limit=0`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSizeLoading(false);
        if (data.status == 200) {
          setSizes(data.body);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setSizeLoading(false);
        toast.warning(error);
      });
  }, []);

  // Get Single Parent Category
  useEffect(() => {
    if (parCatSlug && !subCatSlug) {
      fetch(`${SERVER_URL}/parentCategories?slug=${parCatSlug}`, {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            setParentCategory(data.body[0]);
            setSubCategory(null);
            setChildCategory(null);
          } else {
            toast.warning(data.message);
          }
        })
        .catch((error) => {
          toast.warning(error);
        });
    }
  }, [parCatSlug, subCatSlug, childCatSlug]);

  // Get Single Sub Category
  useEffect(() => {
    if (subCatSlug && !childCatSlug) {
      fetch(`${SERVER_URL}/subCategories?slug=${subCatSlug}`, {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            setSubCategory(data.body[0]);
            setChildCategory(null);
            setParentCategory(null);
          } else {
            toast.warning(data.message);
          }
        })
        .catch((error) => {
          toast.warning(error);
        });
    }
  }, [parCatSlug, subCatSlug, childCatSlug]);

  // Get Single Child Category
  useEffect(() => {
    if (childCatSlug) {
      fetch(`${SERVER_URL}/childCategories?slug=${childCatSlug}`, {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            setChildCategory(data.body[0]);
            setParentCategory(null);
            setSubCategory(null);
          } else {
            toast.warning(data.message);
          }
        })
        .catch((error) => {
          toast.warning(error);
        });
    }
  }, [parCatSlug, subCatSlug, childCatSlug]);

  // Get Sub Category
  useEffect(() => {
    if (parCatSlug && !subCatSlug) {
      setCategoriesLoading(true);
      fetch(`${SERVER_URL}/subCategories?parCatSlug=${parCatSlug}&limit=0`, {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCategoriesLoading(false);
          if (data.status == 200) {
            setSubCategories(data.body || []);
          } else {
            toast.warning(data.message);
          }
        })
        .catch((error) => {
          setCategoriesLoading(false);
          toast.warning(error);
        });
    } else {
      setSubCategories([]);
      setCategoriesLoading(false);
    }
  }, [parCatSlug, subCatSlug]);

  // Get Child Category
  useEffect(() => {
    if (subCatSlug) {
      setCategoriesLoading(true);
      fetch(`${SERVER_URL}/childCategories?subCatSlug=${subCatSlug}&limit=20`, {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            setChildCategories(data.body || []);
          } else {
            toast.warning(data.message);
          }
          setCategoriesLoading(false);
          // console.log("Child", data.body);
        })
        .catch((error) => {
          setCategoriesLoading(false);
          toast.warning(error);
        });
    }
  }, [subCatSlug]);

  // Get Banners
  useEffect(() => {
    setBannerLoading(true);
    fetch(`${SERVER_URL}/settings`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBannerLoading(false);
        if (data.status == 200) {
          const pathname = history.location.pathname;
          const filteredBanner = data.body.breadcrumbBanner.filter((item) => {
            if (item.webpageUrl == pathname) {
              return item.image;
            }
          });
          if (filteredBanner.length) {
            setBreadcrumbBanner({
              image: filteredBanner[0].image,
              textColor: filteredBanner[0].textColor,
            });
          } else {
            setBreadcrumbBanner({ image: "", textColor: "" });
          }
        } else {
          toast.warning(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setBannerLoading(false);
        // toast.warning(error);
      });
  }, [history.location.pathname]);

  const pageHandler = (e, page) => {
    e.preventDefault();
    setPagination({
      ...pagination,
      skip: page == 1 ? 0 : (page - 1) * pagination.limit,
      currentPage: page,
    });
  };

  const previousPageHandler = (e) => {
    e.preventDefault();

    setPagination({
      ...pagination,
      currentPage: pagination.currentPage == 1 ? 1 : pagination.currentPage - 1,
      skip:
        pagination.currentPage == 1
          ? 0
          : (pagination.currentPage - 2) * pagination.limit,
    });
  };

  const nextPageHandler = (e) => {
    e.preventDefault();

    setPagination({
      ...pagination,
      currentPage:
        pagination.currentPage == pagination.totalPage
          ? pagination.totalPage
          : pagination.currentPage + 1,
      skip:
        pagination.currentPage == pagination.totalPage
          ? 0
          : pagination.currentPage * pagination.limit,
    });
  };

  // Scroll to Div
  useEffect(() => {
    const reftp = document.getElementById("refTP");
    // myRef?.current.scrollIntoView();
  }, [parCatSlug, subCatSlug, childCatSlug]);

  // Material Handler
  const materialHandler = (evt) => {
    let oldMaterials = [...selectedMaterial];
    if (
      oldMaterials.some((item) => {
        return item == evt.target.value;
      })
    ) {
      oldMaterials = oldMaterials.filter((item) => {
        return item != evt.target.value;
      });
    } else {
      oldMaterials.push(evt.target.value);
    }
    setSelectedMaterial(oldMaterials);
  };

  // Color Handler
  const colorHandler = (evt) => {
    let oldColors = [...selectedColors];
    if (
      oldColors.some((item) => {
        return item == evt.target.value;
      })
    ) {
      oldColors = oldColors.filter((item) => {
        return item != evt.target.value;
      });
    } else {
      oldColors.push(evt.target.value);
    }
    setSelectedColors(oldColors);
  };

  // Size Handler
  const sizeHandler = (evt, id) => {
    evt.preventDefault();
    let oldSizes = [...selectedSizes];
    if (
      oldSizes.some((item) => {
        return item == id;
      })
    ) {
      oldSizes = oldSizes.filter((item) => {
        return item != id;
      });
    } else {
      oldSizes.push(id);
    }
    setSelectedSizes(oldSizes);
  };

  // Get Wishlists
  useEffect(() => {
    // setProductLoading(true);
    fetch(`${SERVER_URL}/wishlists/myWishlist`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setMyWishlists(data.body || []);
        } else {
          // toast.warning(data.message);
        }
      })
      .catch((error) => {
        // setProductLoading(false);
        toast.warning(error);
      });
  }, [addedToWishlist, removeFromWishlist]);

  // Add Product to Wishlist
  const addWishlistHandler = (evt, product) => {
    evt.preventDefault();

    // check customer is logedIn or not
    if (state && state.jwtToken) {
      setAddWishlistLoading(true);
      fetch(`${SERVER_URL}/wishlists`, {
        method: "POST", // or 'PUT'
        body: JSON.stringify({ product: product._id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAddWishlistLoading(false);
          if (data.status == 200) {
            toast.success(data.message);
            setAddedToWishlist(!addedToWishlist);
          } else {
            toast.warning(data.message);
          }
          // console.log("Success:", data);
        })
        .catch((error) => {
          setAddWishlistLoading(false);
          toast.error(error);
        });
    } else {
      toast.warning("Please Login First");
      history.push("/account/login");
    }
  };

  // Remove Product to Wishlist
  const removeWishlistHandler = (evt, wishListId) => {
    evt.preventDefault();
    setRemoveWishlistLoading(true);
    fetch(`${SERVER_URL}/wishlists/${wishListId}`, {
      method: "DELETE", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRemoveWishlistLoading(false);
        if (data.status == 200) {
          toast.success(data.message);
          setRemoveFromWishlist(!removeFromWishlist);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setRemoveWishlistLoading(false);
        toast.error(error);
      });
  };

  const addToCartHandler = async (fromWhere, product, quantity) => {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        name: product.name,
        slug: product.slug,
        price: product.sellingPrice,
        sku: product.sku,
        weight: product.weight,
        length: product["length"],
        width: product.width,
        height: product.height,
        diameter: product.diameter,
        capacity: product.capacity,
        packageContent: product.packageContent,
        pileHeight: product.pileHeight,
        mountingType: product.mountingType,
        brand: product.brand._id,
        care: product.care,
        color: product.color,
        description: product.description,
        craftType: product.craftType,
        material: product.material._id,
        shape: product.shape,
        isReturnable: product.isReturnable,
        returnCondition: product.returnCondition,
        parentCategory: product.parentCategory._id,
        subCategory: product.subCategory._id,
        childCategory: product.childCategory._id,
        productId: product._id,
        quantity: quantity || 1,
        image: product.defaultImage,
        maxOrderQuantity: product.maxOrderQuantity,
      },
    });

    // add to database
    const response = await fetch(`${SERVER_URL}/cartProducts`, {
      body: JSON.stringify({ product: product._id }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();

    if (fromWhere == "BUY_NOW") {
      history.push("/checkout");
    }
  };

  return (
    <>
      <HelmetMetaData
        title={
          childCategory?.seoTitle ||
          subCategory?.seoTitle ||
          parentCategory?.seoTitle
        }
        description={""}
      />

      <Header />

      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb
        title={(childCatSlug || subCatSlug || parCatSlug)
          .toString()
          .toUpperCase()}
        page={childCatSlug || subCatSlug || parCatSlug}
        breadcrumbBanner={breadcrumbBanner}
      />
      {/*====================  End of breadcrumb area  ====================*/}

      {/*====================  shop page content area ====================*/}

      <div className="page-content-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  shop page wrapper  =======*/}
              <div className="page-wrapper">
                <div className="page-content-wrapper">
                  <div className="row">
                    <div className="col-lg-12">
                      {/*=======  shop page header  =======*/}
                      <div className="shop-header">
                        <div className="shop-header__left">
                          <div className="grid-icons">
                            <button
                              data-target="grid three-column"
                              data-tippy="3"
                              data-tippy-inertia="true"
                              data-tippy-animation="fade"
                              data-tippy-delay="50"
                              data-tippy-arrow="true"
                              data-tippy-theme="roundborder"
                              className="active three-column"
                            ></button>
                            <button
                              data-target="grid four-column"
                              data-tippy="4"
                              data-tippy-inertia="true"
                              data-tippy-animation="fade"
                              data-tippy-delay="50"
                              data-tippy-arrow="true"
                              data-tippy-theme="roundborder"
                              className="four-column d-none d-lg-block"
                            ></button>
                            <button
                              data-target="list"
                              data-tippy="List"
                              data-tippy-inertia="true"
                              data-tippy-animation="fade"
                              data-tippy-delay="50"
                              data-tippy-arrow="true"
                              data-tippy-theme="roundborder"
                              className="list-view"
                            ></button>
                          </div>

                          <div className="shop-header__left__message">
                            Showing {pagination.skip + 1} to{" "}
                            {pagination.skip + products.length} of{" "}
                            {pagination.totalRecord} ({pagination.totalPage}{" "}
                            Pages)
                          </div>
                        </div>

                        <div className="shop-header__right">
                          <div className="single-select-block d-inline-block">
                            <span className="select-title">Show:</span>
                            <select
                              onChange={(evt) => {
                                setPagination({
                                  ...pagination,
                                  limit: evt.target.value,
                                });
                              }}
                            >
                              <option value="9">9</option>
                              <option value="18">18</option>
                              <option value="27">27</option>
                              <option value="0">All</option>
                            </select>
                          </div>

                          <div className="single-select-block d-inline-block">
                            <span className="select-title">Sort By:</span>
                            <select
                              className="pr-0"
                              onChange={(evt) => {
                                setSort(evt.target.value);
                              }}
                            >
                              <option value="default">Default</option>
                              <option value="name-a-z">Name (A - Z)</option>
                              <option value="name-z-a">Name (Z - A)</option>
                              <option value="price-l-h">{`Price (Low > High)`}</option>
                              <option value="price-h-l">{`Price (High > Low)`}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {/*=======  End of shop page header  =======*/}
                    </div>
                    <div className="col-lg-3 order-2 order-lg-1">
                      {/*=======  page sidebar wrapper =======*/}
                      <div className="page-sidebar-wrapper">
                        {/*=======  single sidebar widget  =======*/}
                        <div className="single-sidebar-widget">
                          <h4 className="single-sidebar-widget__title">
                            Categories
                          </h4>

                          {categoriesLoading
                            ? [...Array(5)].map((_, __) => {
                                return <CategorySkeletonLoader key={__} />;
                              })
                            : ""}

                          <ul className="single-sidebar-widget__category-list">
                            {subCategories.map((category, index) => {
                              return (
                                <li key={index}>
                                  <Link to={`/${parCatSlug}/${category.slug}`}>
                                    {category.name}{" "}
                                    {/* <span class="counter">7</span> */}
                                  </Link>
                                </li>
                              );
                            })}

                            {childCategories.map((category, index) => {
                              return (
                                <li key={index}>
                                  <Link
                                    to={`/${parCatSlug}/${subCatSlug}/${category.slug}`}
                                  >
                                    {category.name}{" "}
                                    {/* <span class="counter">7</span> */}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        {/*=======  End of single sidebar widget  =======*/}
                        {/*=======  single sidebar widget  =======*/}
                        <div className="single-sidebar-widget">
                          <div className="sidebar-sub-widget-wrapper">
                            <div className="sidebar-sub-widget">
                              <h4 className="sidebar-sub-widget__title sidebar-sub-widget__title--price-title">
                                Fillter By Price
                              </h4>
                              <div className="sidebar-price p-0">
                                <MultiRangeSlider
                                  min={0}
                                  max={10000}
                                  onChange={({ min, max }) =>
                                    setRange({
                                      ...range,
                                      min: min,
                                      max: max,
                                    })
                                  }
                                />
                                <div className="pt-4">
                                  {/* <button
                                    onClick={rangeFilterHandler}
                                    className={
                                      useRangeFilter
                                        ? "btn btn-dark rounded-0"
                                        : "btn btn-outline-dark rounded-0"
                                    }
                                  >
                                    Apply
                                  </button> */}
                                </div>
                                {/* <input
                                  type="text"
                                  onChange={(evt) => {
                                    console.log(evt.target);
                                  }}
                                  id="price-amount"
                                  className="price-amount"
                                /> */}
                              </div>
                            </div>
                            <div className="sidebar-sub-widget">
                              <h4 className="sidebar-sub-widget__title sidebar-sub-widget__title--abs-icon mb-3">
                                Material
                              </h4>

                              {materialLoading
                                ? [...Array(5)].map((_, __) => {
                                    return <CategorySkeletonLoader key={__} />;
                                  })
                                : ""}
                              <ul className="single-sidebar-widget__category-list single-sidebar-widget__category-list--abs-icon">
                                {materials.map((material, index) => {
                                  return (
                                    <li
                                      key={`material-${index}`}
                                      className={"mb-2"}
                                    >
                                      <div class="form-check">
                                        <input
                                          style={{
                                            padding: "7px",
                                            marginRight: "12px",
                                          }}
                                          class="form-check-input"
                                          type="checkbox"
                                          value={material._id}
                                          onChange={materialHandler}
                                          id={`materialCheckBox-${index}`}
                                        />
                                        <label
                                          class="form-check-label pl-3"
                                          for={`materialCheckBox-${index}`}
                                        >
                                          {material.name}
                                        </label>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>

                            {/* <div className="sidebar-sub-widget">
                              <h4 className="sidebar-sub-widget__title sidebar-sub-widget__title--abs-icon">
                                Select By Color
                              </h4>
                              {colorLoading
                                ? [...Array(5)].map((_, __) => {
                                    return <CategorySkeletonLoader key={__} />;
                                  })
                                : ""}
                              <ul className="single-sidebar-widget__category-list single-sidebar-widget__category-list--abs-icon pt-2">
                                {colors.map((color, index) => {
                                  return (
                                    <li
                                      key={`color-${index}`}
                                      className={"mb-2"}
                                    >
                                      <div class="form-check">
                                        <input
                                          style={{
                                            padding: "7px",
                                            marginRight: "12px",
                                          }}
                                          class="form-check-input"
                                          type="checkbox"
                                          value={color._id}
                                          onChange={colorHandler}
                                          id={`colorCheckBox-${index}`}
                                        />
                                        <label
                                          class="form-check-label pl-3"
                                          for={`colorCheckBox-${index}`}
                                        >
                                          {color.name}
                                        </label>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div> */}
                          </div>
                        </div>
                        {/*=======  End of single sidebar widget  =======*/}
                        {/*=======  single sidebar widget  =======*/}
                        {/* <div className="single-sidebar-widget">
                          <h4 className="single-sidebar-widget__title">
                            Select Sizes
                          </h4>
                          {sizeLoading
                            ? [...Array(5)].map((_, __) => {
                                return <CategorySkeletonLoader key={__} />;
                              })
                            : ""}
                          <ul className="single-sidebar-widget__tag-list">
                            {sizes.map((size, index) => {
                              return (
                                <li key={`size-${index}`}>
                                  <a
                                    className={
                                      selectedSizes.some((s) => {
                                        return s == size._id;
                                      })
                                        ? "active"
                                        : ""
                                    }
                                    href="#"
                                    onClick={(evt) => {
                                      sizeHandler(evt, size._id);
                                    }}
                                  >
                                    {size.name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div> */}
                        {/*=======  End of single sidebar widget  =======*/}
                      </div>
                      {/*=======  End of page sidebar wrapper  =======*/}
                    </div>

                    <div className="col-lg-9 order-1 order-lg-2">
                      {/*=======  shop page content  =======*/}
                      <div className="shop-page-content">
                        <div className="row shop-product-wrap grid three-column">
                          {productsLoading ? (
                            [...Array(9)].map(() => {
                              return (
                                <div className="col-md-4">
                                  <div className="skeleton">
                                    <div className="s-img"></div>
                                    <div className="s-line first"></div>
                                    <div className="s-line second"></div>
                                  </div>
                                </div>
                              );
                            })
                          ) : products.length ? (
                            products.map((item, index) => {
                              const discountPercent = Math.ceil(
                                100 -
                                  (Number.parseInt(item.sellingPrice) * 100) /
                                    Number.parseInt(item.mrp)
                              );

                              // Calculate avarage reviews
                              let avarageRating = 0;
                              const sumOfRatings = item.reviews.reduce(
                                (accumulator, current) => {
                                  return accumulator + current.rating;
                                },
                                0
                              );
                              avarageRating = (
                                sumOfRatings / item.reviews.length
                              ).toFixed(1);

                              // Check Item in available in the cart or not
                              let availableInCart = false;

                              if (state && state.cart.length) {
                                let available = state.cart.some((p) => {
                                  return p.productId == item._id;
                                });
                                if (available) availableInCart = true;
                              }

                              // Check Item in available in the wishlist or not
                              let availableInWishlist = false;

                              let available = myWishlists.some((p) => {
                                return p.product._id == item._id;
                              });
                              if (available) availableInWishlist = true;
                              // if (myWishlists.length) {
                              // }

                              return (
                                <div className="col-12 col-lg-4 col-md-4 col-sm-6">
                                  {/*=======  product grid view  =======*/}
                                  <ProductCard
                                    availableInCart={availableInCart}
                                    availableInWishlist={availableInWishlist}
                                    product={item}
                                    removeWishlistHandler={
                                      removeWishlistHandler
                                    }
                                    category={item.parentCategory}
                                    avarageRating={avarageRating}
                                    addToCartHandler={addToCartHandler}
                                    addWishlistHandler={addWishlistHandler}
                                    addWishlistLoading={addWishlistLoading}
                                    myWishlists={myWishlists}
                                    removeWishlistLoading={
                                      removeWishlistLoading
                                    }
                                    discountPercent={discountPercent}
                                  />
                                  {/*=======  End of product grid view  =======*/}
                                  {/*=======  list view product  =======*/}
                                  <div className="single-grid-product single-grid-product--list-view list-view-product">
                                    <div className="single-grid-product__image single-grid-product--list-view__image">
                                      <div className="single-grid-product__label">
                                        <span className="sale">
                                          -{discountPercent}
                                        </span>
                                        <span className="new">New</span>
                                      </div>
                                      <Link to={`/product/${item.slug}`}>
                                        <img
                                          width="600"
                                          height="800"
                                          src={item.defaultImage}
                                          className="img-fluid"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="800"
                                          src={
                                            item.images.length
                                              ? item.images[0].url
                                              : item.defaultImage
                                          }
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </Link>

                                      <div className="hover-icons">
                                        {/* Add to cart */}
                                        {availableInCart ? (
                                          <Link
                                            to="/cart"
                                            title="Available in Cart"
                                          >
                                            <i className="ion-bag text-danger"></i>
                                          </Link>
                                        ) : (
                                          <Link
                                            onClick={() => {
                                              addToCartHandler("", item);
                                            }}
                                            title="Add to Cart"
                                          >
                                            <i className="ion-bag"></i>
                                          </Link>
                                        )}
                                        {/* Wishlist */}
                                        {availableInWishlist ? (
                                          <Link
                                            title="Available in Wishlist"
                                            onClick={(evt) => {
                                              const wishList =
                                                myWishlists.filter((itn) => {
                                                  return (
                                                    itn.product._id == item._id
                                                  );
                                                });
                                              removeWishlistHandler(
                                                evt,
                                                wishList[0]._id
                                              );
                                            }}
                                          >
                                            <i className="ion-heart text-danger"></i>
                                          </Link>
                                        ) : (
                                          <Link
                                            onClick={(evt) => {
                                              addWishlistHandler(evt, item);
                                            }}
                                            title="Add to Wishlist"
                                          >
                                            <i className="ion-heart"></i>
                                          </Link>
                                        )}
                                        <a href="javascript:void(0)">
                                          <i className="ion-android-options"></i>
                                        </a>
                                        <a
                                          href="javascript:void(0)"
                                          data-bs-toggle="modal"
                                          data-bs-target="#quick-view-modal-container"
                                        >
                                          <i className="ion-android-open"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="single-grid-product__content single-grid-product--list-view__content">
                                      <div className="category">
                                        <Link
                                          href={`/${item.parentCategory.slug}`}
                                        >
                                          {item.parentCategory.name}
                                        </Link>
                                      </div>
                                      <h3 className="single-grid-product__title single-grid-product--list-view__title">
                                        <Link to={`/product/${item.slug}`}>
                                          {item.name}
                                        </Link>
                                      </h3>
                                      <div className="rating">
                                        <Rating
                                          readonly
                                          emptySymbol="fa fa-star-o fa-1x"
                                          fullSymbol="fa fa-star fa-1x text-danger"
                                        />
                                      </div>
                                      <p className="single-grid-product__price single-grid-product--list-view__price">
                                        <span className="discounted-price">
                                          <i className="fa fa-inr"></i>
                                          {item.sellingPrice}
                                        </span>{" "}
                                        <span className="main-price discounted">
                                          <i className="fa fa-inr"></i>
                                          {item.mrp}
                                        </span>
                                      </p>
                                      <p className="single-grid-product--list-view__product-short-desc">
                                        {/* Color: {item.color.name} <br /> */}
                                        {/* Size: {item.size.name} <br /> */}
                                        Material: {item.material.name} <br />
                                      </p>
                                    </div>
                                  </div>
                                  {/*=======  End of list view product  =======*/}
                                </div>
                              );
                            })
                          ) : (
                            <div className="alert alert-danger">
                              No Product Found
                            </div>
                          )}
                        </div>
                      </div>

                      {/*=======  pagination area =======*/}
                      <div className="pagination-area">
                        <div className="pagination-area__left">
                          Showing {pagination.skip + 1} to{" "}
                          {pagination.skip + products.length} of{" "}
                          {pagination.totalRecord} ({pagination.totalPage}{" "}
                          Pages)
                        </div>
                        <div className="pagination-area__right">
                          <ul className="pagination-section">
                            {pagination.currentPage == 1 ? (
                              <li>
                                <span className="Previous text-muted" href="#">
                                  Previous
                                </span>
                              </li>
                            ) : (
                              <li>
                                <a
                                  onClick={previousPageHandler}
                                  className="Previous"
                                  href="#"
                                >
                                  Previous
                                </a>
                              </li>
                            )}
                            {[...Array(pagination.totalPage)].map((_, i) => {
                              return (
                                <li>
                                  <a
                                    href="#"
                                    onClick={(e) => pageHandler(e, i + 1)}
                                  >
                                    {i + 1}
                                  </a>
                                </li>
                              );
                            })}

                            {pagination.totalPage <= pagination.currentPage ? (
                              <li>
                                <span className="Next text-muted" href="#">
                                  Next
                                </span>
                              </li>
                            ) : (
                              <li>
                                <a
                                  onClick={nextPageHandler}
                                  className="Next"
                                  href="#"
                                >
                                  Next
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                      {/*=======  End of pagination area  =======*/}
                      {/*=======  End of shop page content  =======*/}
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of shop page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of shop page content area  ====================*/}

      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {AddLibrary("/assets/js/vendors.js")}
      {AddLibrary("/assets/js/active.js")}
      {/* {AddLibrary(
        "https://ionden.com/a/plugins/ion.rangeSlider/static/js/ion-rangeSlider/ion.rangeSlider.js"
      )}
      {AddLibrary(
        "https://ionden.com/a/plugins/ion.rangeSlider/static/css/ion.rangeSlider.css"
      )} */}
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default Listing;
