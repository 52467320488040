import React from "react";
import { FacebookShareButton, FacebookIcon } from "react-share";

export default function FacebookSharingButton({ url, quotes, hashtag }) {
  console.log(window.location.href);
  return (
    <div className="social-share-buttons">
      <FacebookShareButton
        url={url || window.location.href}
        quote={quotes || "Ferra Indica - World is yours to explore"}
        hashtag={hashtag || "#ferraindica"}
        // className={""}
      >
        <a href="" className="facebook">
          <i class="fa fa-facebook"></i>
        </a>
      </FacebookShareButton>
    </div>
  );
}
