import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SERVER_URL } from "../config";

const Footer = () => {
  const [socialMedia, setSocialMedia] = useState({});
  const [contactUs, setContactUs] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [url, setUrl] = useState("");
  const [urlLoading, setUrlLoading] = useState(false);

  // Get Contact Us details
  useEffect(() => {
    fetch(`${SERVER_URL}/settings/`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setSocialMedia(data.body.socialMedia || {});
          setContactUs(data.body.contactUs || {});
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        dataLoading(false);
        toast.warning(error);
      });
  }, []);

  const whatsappChatHandler = (evt) => {
    evt.preventDefault();
    const url = window.location.href;
    window.open(
      `https://wa.me/${
        contactUs.whatsappNumber || 9117162463
      }?text=Hi, I am interested in ${url}`,
      "_blank"
    );
  };

  return (
    <>
      <ToastContainer />
      <div className="footer-area footer-area--light-bg">
        <div className="footer-navigation footer-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/*=======  footer navigation wrapper  =======*/}
                <div className="footer-navigation-wrapper">
                  <div className="row">
                    <div className="col-lg-4 col-md-4">
                      {/*=======  footer short desc  =======*/}
                      <div className="footer-short-desc">
                        <div className="image">
                          <Link to="/">
                            <img
                              src="/assets/img/logo.png"
                              className="img-fluid"
                              alt=""
                              width="93"
                              height="25"
                            />
                          </Link>
                        </div>
                        <p className="message">
                          From our heartland into your home we bring you the
                          very best from renowned artisans with the hope that
                          the business will encourage & support the succeeding
                          generation of artisans.
                        </p>
                        <div className="footer-social-section">
                          <h4 className="social-title">Follow Us On Social:</h4>

                          <ul className="social-link">
                            <li>
                              {socialMedia.facebook ? (
                                <a
                                  target={"_black"}
                                  href={socialMedia.facebook}
                                >
                                  <i className="fa fa-facebook"></i>
                                </a>
                              ) : (
                                ""
                              )}
                            </li>
                            <li>
                              {socialMedia.instagram ? (
                                <a
                                  target={"_black"}
                                  href={socialMedia.instagram}
                                >
                                  <i className="fa fa-instagram"></i>
                                </a>
                              ) : (
                                ""
                              )}
                            </li>
                            <li>
                              {socialMedia.twitter ? (
                                <a target={"_black"} href={socialMedia.twitter}>
                                  <i className="fa fa-twitter"></i>
                                </a>
                              ) : (
                                ""
                              )}
                            </li>
                            <li>
                              {socialMedia.youtube ? (
                                <a target={"_black"} href={socialMedia.youtube}>
                                  <i className="fa fa-youtube"></i>
                                </a>
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/*=======  End of footer short desc  =======*/}
                    </div>
                    <div className="col-lg-4 col-md-4">
                      {/*=======  single footer widget  =======*/}
                      <div className="single-footer-widget">
                        <h3 className="single-footer-widget__title">Links</h3>
                        <div className="single-footer-widget__content">
                          <ul className="footer-navigation">
                            <li>
                              <Link to="/about">
                                <i className="fa fa-angle-right"></i> About Us
                              </Link>
                            </li>
                            <li>
                              <Link to="/contact">
                                <i className="fa fa-angle-right"></i> Contact Us
                              </Link>
                            </li>
                            <li>
                              <Link to="/offers">
                                <i className="fa fa-angle-right"></i> Offers
                              </Link>
                            </li>
                            <li>
                              <Link to="/blog">
                                <i className="fa fa-angle-right"></i> Blog
                              </Link>
                            </li>

                            <li>
                              <Link to="/privacy-policy">
                                <i className="fa fa-angle-right"></i> Privacy
                                Policy
                              </Link>
                            </li>
                            <li>
                              <Link to="/terms-and-conditions">
                                <i className="fa fa-angle-right"></i> Terms
                                &amp; Conditions
                              </Link>
                            </li>
                            <li>
                              <Link to="/shipping-policies">
                                <i className="fa fa-angle-right"></i> Shipping
                                Policies
                              </Link>
                            </li>
                            <li>
                              <Link to="/return-refund-policies">
                                <i className="fa fa-angle-right"></i> Return
                                &amp; Refund Policies
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/*=======  End of single footer widget  =======*/}
                    </div>

                    <div className="col-lg-4 col-md-4">
                      {/*=======  single footer widget  =======*/}
                      <div className="single-footer-widget">
                        <h3 className="single-footer-widget__title">Address</h3>
                        <div className="single-footer-widget__content">
                          <ul className="footer-navigation">
                            <li className="d-flex flex-row">
                              <div className="Ficon">
                                {" "}
                                <i
                                  className="fa fa-map-marker"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="message">
                                {contactUs.address || "Not Available"}
                              </div>
                            </li>

                            <li className="d-flex flex-row">
                              <div className="Ficon">
                                <i className="fa fa-envelope"></i>
                              </div>

                              <div className="message">
                                <a
                                  href={`mailto:${
                                    contactUs.email || "info@ferraindica.com"
                                  }`}
                                >
                                  {contactUs.email || "info@ferraindica.com"}
                                </a>
                              </div>
                            </li>

                            <li className="d-flex flex-row">
                              <div className="Ficon">
                                <i className="fa fa-phone"></i>
                              </div>

                              <div className="message">
                                <a
                                  href={`tel:${
                                    contactUs.mobile || "9117162463"
                                  }`}
                                >
                                  +91 {contactUs.mobile || "9117162463"}
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/*=======  End of single footer widget  =======*/}
                    </div>
                  </div>
                </div>
                {/*=======  End of footer navigation wrapper  =======*/}
              </div>
            </div>
          </div>
        </div>

        <div className="footer-copyright">
          <div className="container wide">
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-copyright-wrapper">
                  <div className="container">
                    <div className="row align-items-center no-gutters">
                      <div className="col-md-12 text-center">
                        <div className="copyright-text">
                          Copyright © {new Date().getFullYear()}{" "}
                          <Link to={"/"}>Ferra Indica</Link>. All Rights
                          Reserved.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Whatsapp">
        <a onClick={whatsappChatHandler}>
          <img src="/assets/img/whatsapp-icon.png" />
        </a>
      </div>
    </>
  );
};

export default Footer;
