import React from "react";
import { TwitterShareButton, TwitterIcon } from "react-share";
export default function TwitterSharingButton({ url, quotes, hashtag }) {
  return (
    <div className="social-share-buttons">
      <TwitterShareButton
        url={url || window.location.href}
        quote={quotes || "Ferra Indica - World is yours to explore"}
        hashtag={hashtag || "#ferraindica"}
        //   className={classes.socialMediaButton}
      >
        {/* <TwitterIcon size={47} /> */}
        <a href="" className="twitter">
          <i className="fa fa-twitter"></i>
        </a>
      </TwitterShareButton>
    </div>
  );
}
