import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = ({ text }) => {
  return (
    <div class="col-md-12 p-5 mx-auto text-center shadow-sm">
      <h1 className="text-lg">404</h1>
      <h2>{text || "UH OH! You're lost."}</h2>
      <p>
        The page you are looking for does not exist. How you got here is a
        mystery. But you can click the button below to go back to the homepage.
      </p>
      <Link to="/" class="btn btn-success px-5 py-2">
        HOME
      </Link>
    </div>
  );
};

export default PageNotFound;
