import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../config";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomerContext } from "../Routes";

import NewsLater from "../components/NewsLater";
import Breadcrumb from "../components/Breadcrumb";
import Loader from "../components/Loader";
import Header from "../layouts/Header";

const CreateNewPassword = () => {
  const history = useHistory();
  const scrollRef = useRef(null);
  const [formData, setFormData] = useState({
    mobile: "",
  });

  const [loginLoading, setLoginLoading] = useState(false);
  const [regLoading, setRegLoading] = useState(false);
  const { state, dispatch } = useContext(CustomerContext);

  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const createPasswordHandler = (evt) => {
    evt.preventDefault();
    setIsLoading(true);
    const { mobile, token } =
      JSON.parse(localStorage.getItem("resetPassword")) || {};

    if (!token) {
      toast.success("Please Enter Mobile !");
      history.push("/forgot-password");
      return;
    }

    if (!password || !cPassword) {
      toast.error("Enter the Password !");
      setIsLoading(false);
      return;
    }

    if (password !== cPassword) {
      toast.error("Confirm Password is not Same !");
      setIsLoading(false);
      return;
    }

    const customerData = {
      password,
    };
    fetch(SERVER_URL + "/customers/updatePassword", {
      method: "PUT",
      body: JSON.stringify(customerData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoading(false);
          if (result.status === 200) {
            toast.success(result.message);
            localStorage.removeItem("resetPassword");
            history.push("/account/login");
          } else {
            const keys = Object.keys(result.errors);
            keys.forEach((key) => {
              toast.error(result.errors[key]);
            });
            toast.error(result.message);
          }
        },
        (error) => {
          setIsLoading(false);
          toast.error(error);
        }
      );
  };

  // Scroll To Top
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, []);

  return (
    <>
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb title="Create Password" page="create-password" />
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div className="page-content-area" ref={scrollRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div className="page-wrapper">
                <div className="page-content-wrapper">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-6 m-auto">
                      {/* Forget Password*/}
                      <form action="#" onSubmit={createPasswordHandler}>
                        <div className="login-form">
                          <h4 className="login-title">Create New Password</h4>

                          <div className="row">
                            <div className="col-md-12 col-12">
                              <label>New Password *</label>
                              <input
                                type="password"
                                value={password}
                                placeholder="Enter Password"
                                onChange={(evt) => {
                                  setPassword(evt.target.value);
                                }}
                              />
                            </div>

                            <div className="col-md-12 col-12">
                              <label>Confirm Password *</label>
                              <input
                                type="password"
                                value={cPassword}
                                placeholder="Enter Password"
                                onChange={(evt) => {
                                  setCPassword(evt.target.value);
                                }}
                              />
                            </div>

                            <div className="col-sm-6"></div>

                            <div className="col-sm-6 text-start text-sm-end">
                              <Link
                                to="/account/login"
                                className="forget-pass-link"
                              >
                                {" "}
                                Back to Login?
                              </Link>
                            </div>

                            <div className="col-md-12">
                              <button
                                className="register-button"
                                disabled={isLoading ? true : false}
                              >
                                {isLoading ? <Loader /> : "CREATE"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default CreateNewPassword;
