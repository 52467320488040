import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Rating from "react-rating";
import Loader from "./Loader";
import { Modal } from "react-bootstrap";
import { AddLibrary } from "../helpers";
import Slider from "react-slick";
import { productQuickviewSetting } from "../helpers/slider";
import parse from "html-react-parser";
import { CustomerContext } from "../Routes";
import { toast } from "react-toastify";

const ProductCard = ({
  product,
  discountPercent,
  availableInCart,
  availableInWishlist,
  myWishlists,
  removeWishlistHandler,
  addWishlistHandler,
  removeWishlistLoading,
  addWishlistLoading,
  addToCartHandler,
  category,
  avarageRating,
}) => {
  const history = useHistory();
  useEffect(() => {
    // window.scrollTo(0, 500);
  }, []);
  const { state, dispatch } = useContext(CustomerContext);
  const { cart } = state;
  const [show, setShow] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    AddLibrary("/assets/js/vendors.js");
    AddLibrary("/assets/js/active.js");
    setShow(true);
  };

  return (
    <>
      <div className="single-grid-product mb-4">
        <div className="single-grid-product__image p-image">
          <div className="single-grid-product__label">
            <span className="sale">-{discountPercent}%</span>
            <span className="new">New</span>
          </div>
          <div className="img-container">
            <Link to={`/product/${product.slug}`}>
              <img
                // width="600"
                // height="800"
                // style={{ height: "250px" }}
                src={product.defaultImage}
                className="img-fluid"
                alt=""
              />
              {/* <img
                src={
                  product.images.length
                    ? product.images[0].url
                    : product.defaultImage
                }
                className="img-fluid"
                alt=""
              /> */}
            </Link>
          </div>

          <div className="hover-icons">
            {product.stock ? (
              <>
                {availableInCart ? (
                  <Link to="/cart" title="Available in Cart">
                    <i className="ion-bag text-danger"></i>
                  </Link>
                ) : (
                  <Link
                    onClick={() => {
                      addToCartHandler("", product);
                    }}
                    title="Add to Cart"
                  >
                    <i className="ion-bag"></i>
                  </Link>
                )}
              </>
            ) : (
              <Link
                onClick={(evt) => {
                  evt.preventDefault();
                }}
                title="Out of Stock"
                className="text-danger"
              >
                <i className="fa fa-ban" aria-hidden="true"></i>
              </Link>
            )}

            {/* Wishlist */}
            {availableInWishlist ? (
              <Link
                title="Available in Wishlist"
                onClick={(evt) => {
                  const wishList = myWishlists.filter((item) => {
                    return item.product._id == product._id;
                  });
                  removeWishlistHandler(evt, wishList[0]._id);
                }}
              >
                {removeWishlistLoading ? (
                  <Loader text={""} />
                ) : (
                  <i className="ion-heart text-danger"></i>
                )}
              </Link>
            ) : (
              <Link
                onClick={(evt) => {
                  addWishlistHandler(evt, product);
                }}
                title="Add to Wishlist"
              >
                {addWishlistLoading ? (
                  <Loader text={""} />
                ) : (
                  <i className="ion-heart"></i>
                )}
              </Link>
            )}

            {/* <a href="javascript:void(0)">
              <i className="ion-android-options"></i>
            </a> */}
            <Link onClick={handleShow}>
              <i className="ion-android-open"></i>
            </Link>
          </div>
        </div>
        <div className="single-grid-product__content">
          <div className="single-grid-product__category-rating">
            <span className="category">
              <Link to={`/${category.slug}`}>{category.name}</Link>
            </span>
            <span className="rating">
              <Rating
                readonly
                emptySymbol="fa fa-star-o fa-1x"
                fullSymbol="fa fa-star fa-1x text-danger"
                initialRating={avarageRating}
              />
            </span>
          </div>

          <h3 className="single-grid-product__title">
            {" "}
            <Link to={`/product/${product.slug}`}>
              {product.name.length > 30
                ? `${product.name.slice(0, 30)}..`
                : product.name}
            </Link>
          </h3>
          <p className="single-grid-product__price">
            <span className="discounted-price">
              <i className="fa fa-inr"></i>
              {product.sellingPrice}
            </span>{" "}
            <span className="main-price discounted">
              <i className="fa fa-inr"></i>
              {product.mrp}
            </span>
          </p>
        </div>
      </div>

      {/*====================  quick view ====================*/}
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="col-xl-12 col-lg-12">
            {/*=======  single product main content area  =======*/}
            <div className="single-product-main-content-area">
              <div className="row">
                <div className="col-xl-5 col-lg-6">
                  {/*=======  product details slider area  =======*/}

                  <div className="product-details-slider-area">
                    <div className="big-image-wrapper product-quickview-modal">
                      <Slider {...productQuickviewSetting}>
                        <div className="any-class-name">
                          <img
                            // width="600"
                            // height="800"
                            src={product.defaultImage}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        {product.images.map((img, indx) => {
                          return (
                            <div className="any-class-name" key={indx}>
                              <img
                                // width="600"
                                // height="800"
                                src={img.url}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>

                  {/*=======  End of product details slider area  =======*/}
                </div>
                <div className="col-xl-7 col-lg-6">
                  {/*=======  single product content description  =======*/}
                  <div className="single-product-content-description">
                    <p className="single-info">
                      Category{" "}
                      <Link to={`/${category.slug}`}> {category.name} </Link>
                    </p>
                    <h4 className="product-title">{product.name}</h4>
                    <div className="product-rating">
                      <span className="rating">
                        <Rating
                          initialRating={avarageRating}
                          readonly
                          emptySymbol={"fa fa-star fa-1x"}
                          fullSymbol={"fa fa-star fa-1x text-danger"}
                        />
                      </span>

                      <span className="review-count">
                        {" "}
                        <a href="#">
                          ({product.reviews.length} reviews)
                        </a> | <a href="#">Write A Review</a>{" "}
                      </span>
                    </div>

                    <p className="single-grid-product__price">
                      <span className="discounted-price">
                        {" "}
                        <i className="fa fa-inr"></i>
                        {product.sellingPrice}{" "}
                      </span>{" "}
                      <span className="main-price discounted">
                        <i className="fa fa-inr"></i>
                        {product.mrp}{" "}
                      </span>
                      <span className="single-info px-2">
                        (Inclusive of all taxes)
                      </span>
                    </p>

                    <div className="row">
                      {/* General Information */}
                      <div className="col-md-6">
                        {/* Product Code */}
                        {product.code && (
                          <p className="single-info">
                            Product Code :{" "}
                            <span className="value">{product.code}</span>
                          </p>
                        )}

                        {/* Product Capacity */}
                        {product.capacity && (
                          <p className="single-info">
                            Capacity :{" "}
                            <span className="value">{product.capacity}</span>
                          </p>
                        )}

                        {/* Product packageContent */}
                        {product.packageContent && (
                          <p className="single-info">
                            Package Content :{" "}
                            <span className="value">
                              {product.packageContent}
                            </span>
                          </p>
                        )}

                        {/* Product pileHeight */}
                        {product.pileHeight && (
                          <p className="single-info">
                            Pile Height :{" "}
                            <span className="value">{product.pileHeight}</span>
                          </p>
                        )}

                        {/* Product mountingType */}
                        {product.mountingType && (
                          <p className="single-info">
                            Mounting Type :{" "}
                            <span className="value">
                              {product.mountingType}
                            </span>
                          </p>
                        )}

                        {/* Product care */}
                        {product.care && (
                          <p className="single-info">
                            Care : <span className="value">{product.care}</span>
                          </p>
                        )}

                        {/* Product Color */}
                        {product.color && (
                          <p className="single-info">
                            Color :{" "}
                            <span className="value">{product.color}</span>
                          </p>
                        )}

                        {/* Product make */}
                        {product.make && (
                          <p className="single-info">
                            Make : <span className="value">{product.make}</span>
                          </p>
                        )}

                        {/* Product shape */}
                        {product.shape && (
                          <p className="single-info">
                            Shape :{" "}
                            <span className="value">{product.shape}</span>
                          </p>
                        )}

                        {typeof product.material == "object" ? (
                          <p className="single-info">
                            Material :{" "}
                            <span className="value">
                              {product.material.name}
                            </span>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>

                      {/* Dimension  */}
                      <div className="col-md-6">
                        {/* <h5>Dimensions</h5> */}
                        {product.weight && (
                          <p className="single-info">
                            Weight :{" "}
                            <span className="value">{product.weight}</span>
                          </p>
                        )}

                        {/* Product Length */}
                        {product["length"] && (
                          <p className="single-info">
                            Length :{" "}
                            <span className="value">{product["length"]}</span>
                          </p>
                        )}

                        {/* Product Width */}
                        {product.width && (
                          <p className="single-info">
                            Width :{" "}
                            <span className="value">{product.width}</span>
                          </p>
                        )}

                        {/* Product Height */}
                        {product.height && (
                          <p className="single-info">
                            Height :{" "}
                            <span className="value">{product.height}</span>
                          </p>
                        )}

                        {/* Product Diameter */}
                        {product.diameter && (
                          <p className="single-info">
                            Diameter :{" "}
                            <span className="value">{product.diameter}</span>
                          </p>
                        )}
                      </div>
                    </div>

                    <p
                      className="product-description"
                      style={{
                        margin: "15px 0",
                        padding: "0",
                      }}
                    ></p>

                    {/* Bullets Points */}
                    <ul className="list-group pb-3">
                      {product.bulletPoints &&
                        product.bulletPoints.map((item, _i) => {
                          return (
                            <li className="list-group-item" key={_i}>
                              {item}
                            </li>
                          );
                        })}
                    </ul>

                    {product.stock ? (
                      <div className="product-actions">
                        <div class="quantity-selection d-flex justify-content-center p-0 m-0">
                          {/* <label htmlFor="">Qty</label> */}
                          <button
                            className="btn btn-dark"
                            type="button"
                            onClick={(evt) => {
                              evt.preventDefault();
                              setQuantity((old) => {
                                if (old <= 1) {
                                  toast.warning("Minimum quantity must be 1");
                                  return 1;
                                } else {
                                  return old - 1;
                                }
                              });
                            }}
                          >
                            -
                          </button>
                          <input type="number" value={quantity} min="1" />
                          <button
                            className="btn btn-dark"
                            type="button"
                            onClick={(evt) => {
                              evt.preventDefault();
                              setQuantity((old) => {
                                return old + 1;
                              });
                            }}
                          >
                            +
                          </button>
                        </div>

                        <div className="product-buttons">
                          {cart.some(
                            (value) => value.productId == product._id
                          ) ? (
                            <Link
                              onClick={() => {
                                history.push("/cart");
                              }}
                              className="cart-btn"
                              to="#"
                            >
                              {" "}
                              <i className="ion-bag"></i> ADDED TO CART
                            </Link>
                          ) : (
                            <Link
                              onClick={() => {
                                addToCartHandler("", product, quantity);
                              }}
                              className="cart-btn"
                              to="#"
                            >
                              {" "}
                              <i className="ion-bag"></i> ADD TO CART
                            </Link>
                          )}
                          <span className="wishlist-compare-btn">
                            {availableInWishlist ? (
                              <Link
                                title="Available in Wishlist"
                                onClick={(evt) => {
                                  const wishList = myWishlists.filter(
                                    (item) => {
                                      return item.product._id == product._id;
                                    }
                                  );
                                  removeWishlistHandler(evt, wishList[0]._id);
                                }}
                              >
                                {removeWishlistLoading ? (
                                  <Loader text={""} />
                                ) : (
                                  <i className="ion-heart text-danger"></i>
                                )}
                              </Link>
                            ) : (
                              <Link
                                onClick={(evt) => {
                                  addWishlistHandler(evt, product);
                                }}
                                title="Add to Wishlist"
                              >
                                {addWishlistLoading ? (
                                  <Loader text={""} />
                                ) : (
                                  <i className="ion-heart"></i>
                                )}
                              </Link>
                            )}
                            {/* <a>
                            {" "}
                            <i className="ion-android-options"></i>
                          </a> */}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="product-actions">
                        <div className="product-buttons">
                          <Link
                            onClick={(evt) => {
                              evt.preventDefault();
                            }}
                            className="cart-btn border-danger text-danger"
                            to="#"
                          >
                            {" "}
                            <i className="ion-bag text-danger"></i> OUT OF STOCK
                          </Link>

                          <span className="wishlist-compare-btn">
                            {availableInWishlist ? (
                              <Link
                                title="Available in Wishlist"
                                onClick={(evt) => {
                                  const wishList = myWishlists.filter(
                                    (item) => {
                                      return item.product._id == product._id;
                                    }
                                  );
                                  removeWishlistHandler(evt, wishList[0]._id);
                                }}
                              >
                                {removeWishlistLoading ? (
                                  <Loader text={""} />
                                ) : (
                                  <i className="ion-heart text-danger"></i>
                                )}
                              </Link>
                            ) : (
                              <Link
                                onClick={(evt) => {
                                  addWishlistHandler(evt, product);
                                }}
                                title="Add to Wishlist"
                              >
                                {addWishlistLoading ? (
                                  <Loader text={""} />
                                ) : (
                                  <i className="ion-heart"></i>
                                )}
                              </Link>
                            )}
                            {/* <a>
                            {" "}
                            <i className="ion-android-options"></i>
                          </a> */}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/*=======  End of single product content description  =======*/}
                </div>
              </div>
            </div>
            {/*=======  End of single product main content area  =======*/}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductCard;
