function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      // className={`${className} slider-arrow slider-btn`}
      style={{
        ...style,
        // display: "none",
      }}
      id="carausel-4-columns-2-arrows"
      onClick={onClick}
    >
      <span className="slider-btn slider-prev slick-arrow">
        <i className="fa fa-angle-left"></i>
      </span>
    </div>
  );
}

export default SampleNextArrow;
