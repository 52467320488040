import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ title, page, breadcrumbBanner = {} }) => {
  return (
    <div className="breadcrumb-area section-space--half">
      <div className="container wide">
        <div className="row">
          <div className="col-lg-12">
            {/*=======  breadcrumb wrpapper  =======*/}
            <div
              className="breadcrumb-wrapper breadcrumb-bg"
              style={{
                backgroundImage: `url(${
                  breadcrumbBanner.image ||
                  "/assets/img/backgrounds/cat-banner.jpg"
                })`,
              }}
            >
              {/*=======  breadcrumb content  =======*/}
              <div className="breadcrumb-content">
                <h2
                  className="breadcrumb-content__title"
                  style={{ color: breadcrumbBanner.textColor || "#333" }}
                >
                  {title || "Login - Register"}
                </h2>
                <ul className="breadcrumb-content__page-map">
                  <li style={{ color: breadcrumbBanner.textColor || "#333" }}>
                    <Link
                      to=""
                      style={{ color: breadcrumbBanner.textColor || "inherit" }}
                    >
                      Home
                    </Link>
                  </li>
                  <li
                    className="active"
                    style={{ color: breadcrumbBanner.textColor || "#333" }}
                  >
                    {page || "Login - Register"}
                  </li>
                </ul>
              </div>
              {/*=======  End of breadcrumb content  =======*/}
            </div>
            {/*=======  End of breadcrumb wrpapper  =======*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
