import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../components/Spinner";
import { SERVER_URL } from "../config";
import { CustomerContext } from "../Routes";
import $ from "jquery";

// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = false;
  document.body.appendChild(script);
}

function Header() {
  const history = useHistory();

  const [categories, setCategories] = useState(
    JSON.parse(localStorage.getItem("menu")) || []
  );
  const [categoryLoading, setCategoryLoading] = useState(false);
  const { state, dispatch } = useContext(CustomerContext);
  const { cart } = state;

  const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
  const [contactDetails, setContactDetails] = useState({});
  const [contactDetailsLoading, setContactDetailsLoading] = useState(true);
  const [pathname, setPathname] = useState("");
  const [subtotal, setSubtotal] = useState("");
  const [categoryBanners, setCategoryBanners] = useState({});
  const [imageSliderLoading, setCategoryBannerLoading] = useState(false);

  const [socialMedia, setSocialMedia] = useState({});
  const [query, setQuery] = useState(null);

  // Get Image Sliders
  useEffect(() => {
    fetch(`${SERVER_URL}/settings`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCategoryBannerLoading(false);

        if (data.status == 200) {
          let banners = data.body.categoryBanner;
          let bannerObj = {};
          banners.map((item) => {
            bannerObj[item.category._id] = {
              image: item.image,
              webpageUrl: item.webpageUrl,
            };
          });
          // console.log(bannerObj);
          setCategoryBanners(bannerObj);
          setSocialMedia(data.body.socialMedia);
          setContactDetails(data.body.contactUs);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setCategoryBannerLoading(false);
        // toast.warning(error);
      });
  }, []);

  // Calculate amount
  useEffect(() => {
    // Calculate subtotal
    let sub_total = cart
      .map((product) => product.price * product.quantity)
      .reduce((prev, curr) => prev + curr, 0);

    setSubtotal(sub_total);
  }, [cart]);

  // Get All Parent with sub Categoies
  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("menu"))) {
      setCategoryLoading(true);
    }
    fetch(`${SERVER_URL}/parentCategories?allSubCategories=true`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCategoryLoading(false);
        if (data.status == 200) {
          const sortedData = data.body.sort((a, b) =>
            a.position > b.position ? 1 : -1
          );

          setCategories(sortedData || []);
          localStorage.setItem("menu", JSON.stringify(sortedData));
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setCategoryLoading(false);
        toast.error(error);
      });
  }, []);

  useEffect(() => {
    if (customerInfo && customerInfo.jwtToken) {
      dispatch({ type: "CUSTOMER", payload: customerInfo.jwtToken });
    }

    if (customerInfo && customerInfo.cart.length) {
      dispatch({ type: "UPDATE_STATE", payload: customerInfo });
    }
  }, []);

  // For the active page
  useEffect(() => {
    const path = history.location.pathname.split("/");
    setPathname(`/${path[1]}`);
  }, []);

  // profileMenuHandler
  function profileMenuHandler(evt) {
    evt.preventDefault();
    $("#settings-menu-wrapper").toggleClass("active");
    $("#mini-cart").removeClass("active");
    $("body").addClass("active-overlay");
  }

  // cartMenuHandler
  function cartMenuHandler(evt) {
    evt.preventDefault();

    // $("#minicart-trigger").siblings("#mini-cart").toggleClass("active");
    $("#mini-cart").toggleClass("active");
    $("#settings-menu-wrapper").removeClass("active");
    $("body").addClass("active-overlay");
  }

  function removeOverlay() {
    $("#mini-cart").removeClass("active");
    $("#settings-menu-wrapper").removeClass("active");
    $("body").removeClass("active-overlay");
  }

  // For the Navigation
  useEffect(function () {
    $("#mobile-menu-trigger").on("click", function () {
      $("#offcanvas-mobile-menu").removeClass("inactive").addClass("active");
    });

    $("#offcanvas-menu-close-trigger").on("click", function () {
      $("#offcanvas-mobile-menu").removeClass("active").addClass("inactive");
    });

    // $("#minicart-trigger").on("click", function (e) {
    //   e.preventDefault();

    //   $(this).siblings(".mini-cart").toggleClass("active");
    //   $("#settings-menu-wrapper").removeClass("active");
    //   $("body").addClass("active-overlay");
    // });

    // $("#header-settings-trigger").on("click", function (e) {
    //   e.preventDefault();
    //   $(this).siblings(".settings-menu-wrapper").toggleClass("active");
    //   $("#mini-cart").removeClass("active");
    //   $("body").addClass("active-overlay");
    // });

    /*Minicart Close When Click Outside Minicart*/

    $("body").on("click", function (e) {
      var $target = e.target;
      if (
        !$($target).is(
          ".header-cart-icon, .header-settings-icon, #currency-trigger, #language-trigger"
        ) &&
        !$($target)
          .parents()
          .is(".header-cart-icon, .header-settings-icon, #currency-trigger") &&
        $("body").hasClass("active-overlay")
      ) {
        $(
          ".mini-cart, .settings-menu-wrapper, #currency-menu, #language-menu"
        ).removeClass("active");
        $("body").removeClass("active-overlay");
      }
    });
  }, []);

  const logout = (evt) => {
    evt.preventDefault();
    removeOverlay();
    localStorage.removeItem("customerInfo");
    dispatch({ type: "CLEAR" });
    toast.success("Successfully Logout !!");
    history.push("/account/login");
  };

  function mobileCategoryMenuToggle(menu) {
    $(menu).slideUp();
    // var $offCanvasNav = $(".offcanvas-navigation"),
    //   $offCanvasNavSubMenu = $offCanvasNav.find(".sub-menu");
    // console.log($offCanvasNavSubMenu);
    // /*Add Toggle Button With Off Canvas Sub Menu*/
    // $offCanvasNavSubMenu
    //   .parent()
    //   .prepend('<span className="menu-expand"><i></i></span>');

    // /*Close Off Canvas Sub Menu*/
    // $offCanvasNavSubMenu.slideUp();

    /*Category Sub Menu Toggle*/
    // $offCanvasNav.on("click", "li a, li .menu-expand", function (e) {
    //   var $this = $(this);
    //   console.log("this", $this);
    //   if (
    //     $this
    //       .parent()
    //       .attr("class")
    //       .match(/\b(menu-item-has-children|has-children|has-sub-menu)\b/) &&
    //     ($this.attr("href") === "#" || $this.hasClass("menu-expand"))
    //   ) {
    //     e.preventDefault();
    //     if ($this.siblings("ul:visible").length) {
    //       $this.parent("li").removeClass("active");
    //       $this.siblings("ul").slideUp();
    //     } else {
    //       $this.parent("li").addClass("active");
    //       $this
    //         .closest("li")
    //         .siblings("li")
    //         .removeClass("active")
    //         .find("li")
    //         .removeClass("active");
    //       $this.closest("li").siblings("li").find("ul:visible").slideUp();
    //       $this.siblings("ul").slideDown();
    //     }
    //   }
    // });
  }

  const mobileSubMenuHandler = (evt) => {
    evt.preventDefault();
    // set display none to all sub menu
    const innerMenu = evt.target.nextSibling;

    if (innerMenu.style.display) {
      innerMenu.style.display = null;
    } else {
      innerMenu.style.display = "none";
    }
  };

  return (
    <>
      {/*====================  header area ====================*/}

      <div className="header-area header-sticky">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  header wrapper  =======*/}
              <div className="header-wrapper d-none d-lg-flex">
                {/* logo */}
                <div className="logo">
                  <Link to="/">
                    <img
                      width="93"
                      height="25"
                      src="/assets/img/logo.png"
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </div>
                {/* menu wrapper */}
                <div
                  className="navigation-menu-wrapper"
                  style={{ flexBasis: "70%" }}
                >
                  <nav>
                    <ul className="mega-menu-new">
                      {/* <li>
                        <Link to="/">HOME</Link>
                      </li> */}

                      {categoryLoading ? (
                        <Spinner />
                      ) : (
                        categories.map((item, index) => {
                          return (
                            <li className="menu-item-has-children" key={index}>
                              <Link to={`/${item.slug}`}>{item.name}</Link>
                              <ul className="mega-menu four-column mega-menu-last">
                                {item.subCategories.map((subItem, subIndex) => {
                                  return (
                                    <li key={subIndex}>
                                      <Link
                                        to={`/${item.slug}/${subItem.slug}`}
                                      >
                                        {subItem.name}{" "}
                                      </Link>
                                      <ul>
                                        {subItem.childCategories.map(
                                          (childItem, childIndex) => {
                                            return (
                                              <li key={childIndex}>
                                                <Link
                                                  to={`/${item.slug}/${subItem.slug}/${childItem.slug}`}
                                                >
                                                  {childItem.name}
                                                </Link>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </li>
                                  );
                                })}

                                <li className="megamenu-banner d-none d-lg-block mt-30 w-100">
                                  {categoryBanners[item._id] ? (
                                    <Link
                                      to={categoryBanners[item._id].webpageUrl}
                                      className="mb-0"
                                    >
                                      {/* {console.log(setCategoryBanners)} */}

                                      <img
                                        width="920"
                                        height="183"
                                        src={categoryBanners[item._id].image}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              </ul>
                            </li>
                          );
                        })
                      )}

                      {/* <li>
                        <Link to="/about">ABOUT</Link>
                      </li>

                      <li>
                        <Link to="/contact">CONTACT</Link>
                      </li> */}
                    </ul>
                  </nav>
                </div>
                {/* header icon */}
                <div className="header-icon-wrapper">
                  <ul className="icon-list">
                    <li>
                      <a href="javascript:void(0)" id="search-overlay-trigger">
                        <i className="ion-ios-search-strong"></i>
                      </a>
                    </li>
                    <li>
                      <div className="header-cart-icon">
                        <a
                          href="#"
                          id="minicart-trigger"
                          onClick={cartMenuHandler}
                        >
                          <i className="ion-bag"></i>
                          <span className="counter">
                            {customerInfo && customerInfo.cart.length}
                          </span>
                        </a>
                        {/* mini cart  */}
                        {customerInfo && customerInfo.cart.length ? (
                          <div className="mini-cart" id="mini-cart">
                            <div className="cart-items-wrapper ps-scroll">
                              <div className="">
                                {customerInfo &&
                                  customerInfo.cart.map((item, index) => {
                                    return (
                                      <div
                                        className="single-cart-item"
                                        key={index}
                                      >
                                        <a
                                          href="javascript:void(0)"
                                          className="remove-icon"
                                          onClick={(evt) => {
                                            dispatch({
                                              type: "REMOVE_FROM_CART",
                                              payload: {
                                                productId: item.productId,
                                              },
                                            });
                                          }}
                                        >
                                          <i className="ion-android-close"></i>
                                        </a>
                                        <div className="image">
                                          <Link to={`/product/${item.slug}`}>
                                            <img
                                              width="80"
                                              height="106"
                                              src={item.image}
                                              className="img-fluid"
                                              alt=""
                                            />
                                          </Link>
                                        </div>
                                        <div className="content">
                                          <p className="product-title">
                                            <Link to={`/product/${item.slug}`}>
                                              {item.name}
                                            </Link>
                                          </p>
                                          <p className="count">
                                            <span> {item.quantity} x </span>{" "}
                                            {item.price}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="cart-calculation">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td className="text-start">Sub-Total :</td>
                                    <td className="text-end">
                                      <i className="fa fa-inr"></i> {subtotal}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="text-start">Total :</td>
                                    <td className="text-end">
                                      <i className="fa fa-inr"></i> {subtotal}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="cart-buttons">
                              <Link to="/cart" onClick={removeOverlay}>
                                VIEW CART
                              </Link>
                              <Link to="/checkout" onClick={removeOverlay}>
                                CHECKOUT
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <div className="mini-cart" id="mini-cart">
                            <div className="cart-items-wrapper ps-scroll">
                              <div className="alert alert-danger">
                                Cart is Empty !!
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="header-settings-icon">
                        <a
                          href="javascript:void(0)"
                          className="header-settings-trigger"
                          id="header-settings-trigger"
                          onClick={profileMenuHandler}
                        >
                          <div className="setting-button">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </a>

                        {/* settings menu */}
                        <div
                          className="settings-menu-wrapper"
                          id="settings-menu-wrapper"
                        >
                          <div className="single-settings-block">
                            <h4 className="title">MY ACCOUNT </h4>
                            {state && state.jwtToken ? (
                              <ul>
                                <li>
                                  <Link
                                    to="/account/myAccount"
                                    onClick={removeOverlay}
                                  >
                                    My Account
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    onClick={removeOverlay}
                                    to="/account/myAccount/orders"
                                  >
                                    Orders
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    onClick={removeOverlay}
                                    to="/account/myAccount/wishlists"
                                  >
                                    Wishlist
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    onClick={removeOverlay}
                                    to="/account/myAccount/address"
                                  >
                                    Address
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={logout}>Logout</Link>
                                </li>
                              </ul>
                            ) : (
                              <ul>
                                <li>
                                  <Link
                                    to="/account/register"
                                    onClick={removeOverlay}
                                  >
                                    Register/Login
                                  </Link>
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/*=======  End of header wrapper  =======*/}

              {/*=======  mobile navigation area  =======*/}

              <div className="header-mobile-navigation d-block d-lg-none">
                <div className="row align-items-center">
                  <div className="col-6 col-md-6">
                    <div className="header-logo">
                      <Link to="/">
                        <img
                          width="93"
                          height="25"
                          src="/assets/img/logo.png"
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 col-md-6">
                    <div className="mobile-navigation text-end">
                      <div className="header-icon-wrapper">
                        <ul className="icon-list justify-content-end">
                          <li>
                            <div className="header-cart-icon">
                              <Link to="/cart">
                                <i className="ion-bag"></i>
                                <span className="counter">
                                  {state && state.cart.length}
                                </span>
                              </Link>
                            </div>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              className="mobile-menu-icon"
                              id="mobile-menu-trigger"
                            >
                              <i className="fa fa-bars"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*=======  End of mobile navigation area  =======*/}
            </div>
          </div>
        </div>
      </div>

      {/*=======  offcanvas mobile menu  =======*/}

      <div className="offcanvas-mobile-menu" id="offcanvas-mobile-menu">
        <a
          href="javascript:void(0)"
          className="offcanvas-menu-close"
          id="offcanvas-menu-close-trigger"
        >
          <i className="ion-android-close"></i>
        </a>

        <div className="offcanvas-wrapper">
          <div className="offcanvas-inner-content">
            <div className="offcanvas-mobile-search-area">
              <form
                action="#"
                onSubmit={(evt) => {
                  evt.preventDefault();
                  document.querySelector("#search-overlay").style.display =
                    "none";
                  history.push(`/search?q=${query}`);
                }}
              >
                <input
                  type="search"
                  value={query}
                  onChange={(evt) => {
                    setQuery(evt.target.value);
                  }}
                  placeholder="Search entire store here ..."
                />
                <button type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>

            <nav className="offcanvas-navigation">
              <ul>
                <li className="menu-item-has-children">
                  <Link to="/">HOME</Link>
                </li>

                {categories.map((category) => {
                  return (
                    <li
                      className="menu-item-has-children"
                      key={`${category.id}`}
                    >
                      <Link onClick={mobileSubMenuHandler} to={`#`}>
                        {category.name}
                      </Link>

                      <ul className="sub-menu mobile-sub-menu">
                        {category.subCategories.map((subCategory) => {
                          return (
                            <li key={subCategory._id}>
                              <Link
                                to={`/${category.slug}/${subCategory.slug}`}
                              >
                                {subCategory.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}

                <li className="menu-item-has-children">
                  <Link to="/about">ABOUT</Link>
                </li>

                <li className="menu-item-has-children">
                  <Link to="/contact">CONTACT</Link>
                </li>

                {/* <li
                  className="menu-item-has-children active"
                  onClick={(evt) => mobileCategoryMenuToggle("#homeMenu")}
                >
                  <a href="#">Home</a>
                  <ul className="sub-menu" id="homeMenu">
                    <li>
                      <a href="index.html">Home 01</a>
                    </li>
                    <li>
                      <a href="index-2.html">Home 02</a>
                    </li>
                    <li>
                      <a href="index-3.html">Home 03</a>
                    </li>
                    <li>
                      <a href="index-4.html">Home 04</a>
                    </li>
                    <li>
                      <a href="index-5.html">Home 05</a>
                    </li>
                    <li>
                      <a href="index-6.html">Home 06</a>
                    </li>
                    <li>
                      <a href="index-7.html">Home 07</a>
                    </li>
                    <li>
                      <a href="index-8.html">Home 08</a>
                    </li>
                  </ul>
                </li>
                <li className="menu-item-has-children">
                  <a href="#">Pages</a>
                  <ul className="sub-menu">
                    <li className="menu-item-has-children">
                      <a href="#">Page List One</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="cart.html">Cart</a>
                        </li>
                        <li>
                          <a href="checkout.html">Checkout</a>
                        </li>
                        <li>
                          <a href="wishlist.html">Wishlist</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="#">Page List Two</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="my-account.html">My Account</a>
                        </li>
                        <li>
                          <a href="login-register.html">Login Register</a>
                        </li>
                        <li>
                          <a href="faq.html">FAQ</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="#">Page List Three</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="compare.html">Compare</a>
                        </li>
                        <li>
                          <a href="contact.html">Contact</a>
                        </li>
                        <li>
                          <a href="about.html">About Us</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu-item-has-children">
                  <a href="#">Shop</a>
                  <ul className="sub-menu">
                    <li className="menu-item-has-children">
                      <a href="#">Shop Grid</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="shop-3-column.html">shop 3 column</a>
                        </li>
                        <li>
                          <a href="shop-4-column.html">shop 4 column</a>
                        </li>
                        <li>
                          <a href="shop-left-sidebar.html">shop left sidebar</a>
                        </li>
                        <li>
                          <a href="shop-right-sidebar.html">
                            shop right sidebar
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="#">Shop List</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="shop-list.html">shop List</a>
                        </li>
                        <li>
                          <a href="shop-list-left-sidebar.html">
                            shop List Left Sidebar
                          </a>
                        </li>
                        <li>
                          <a href="shop-list-right-sidebar.html">
                            shop List Right Sidebar
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="#">Single Product One</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="single-product.html">Single Product</a>
                        </li>
                        <li>
                          <a href="single-product-variable.html">
                            Single Product variable
                          </a>
                        </li>
                        <li>
                          <a href="single-product-affiliate.html">
                            Single Product affiliate
                          </a>
                        </li>
                        <li>
                          <a href="single-product-group.html">
                            Single Product group
                          </a>
                        </li>
                        <li>
                          <a href="single-product-tabstyle-2.html">
                            Tab Style 2
                          </a>
                        </li>
                        <li>
                          <a href="single-product-tabstyle-3.html">
                            Tab Style 3
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="#">Single Product Two</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="single-product-gallery-left.html">
                            Gallery Left
                          </a>
                        </li>
                        <li>
                          <a href="single-product-gallery-right.html">
                            Gallery Right
                          </a>
                        </li>
                        <li>
                          <a href="single-product-sticky-left.html">
                            Sticky Left
                          </a>
                        </li>
                        <li>
                          <a href="single-product-sticky-right.html">
                            Sticky Right
                          </a>
                        </li>
                        <li>
                          <a href="single-product-slider-box.html">
                            Slider Box
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu-item-has-children">
                  <a href="#">Blog</a>
                  <ul className="sub-menu">
                    <li>
                      <a href="blog-left-sidebar.html">Blog Left Sidebar</a>
                    </li>
                    <li>
                      <a href="blog-right-sidebar.html">Blog Right Sidebar</a>
                    </li>
                    <li>
                      <a href="blog-post-left-sidebar.html">
                        Blog Post Left Sidebar
                      </a>
                    </li>
                    <li>
                      <a href="blog-post-right-sidebar.html">
                        Blog Post Right Sidebar
                      </a>
                    </li>
                    <li>
                      <a href="blog-post-image-format.html">
                        Blog Post Image Format
                      </a>
                    </li>
                    <li>
                      <a href="blog-post-image-gallery.html">
                        Blog Post Image Gallery
                      </a>
                    </li>
                    <li>
                      <a href="blog-post-audio-format.html">
                        Blog Post Audio Format
                      </a>
                    </li>
                    <li>
                      <a href="blog-post-video-format.html">
                        Blog Post Video Format
                      </a>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </nav>

            <div className="offcanvas-settings">
              <nav
                className="offcanvas-navigation"
                // onClick={mobileCategoryMenuToggle}
              >
                <ul>
                  {state && state.jwtToken ? (
                    <li className="menu-item-has-children">
                      <Link to="/account/myAccount">MY ACCOUNT </Link>
                    </li>
                  ) : (
                    <li className="menu-item-has-children">
                      <Link to="/login">Register/Login </Link>
                    </li>
                  )}
                  {/* <li className="menu-item-has-children">
                    <a href="#">MY ACCOUNT </a>
                    <ul className="sub-menu">
                      <li>
                        <a href="login-register.html">Register</a>
                      </li>
                      <li>
                        <a href="login-register.html">Login</a>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </nav>
            </div>

            <div className="offcanvas-widget-area">
              <div className="off-canvas-contact-widget">
                <div className="header-contact-info">
                  <ul className="header-contact-info__list">
                    <li>
                      <i className="ion-android-phone-portrait"></i>{" "}
                      <a href={`tel:${contactDetails.mobile}`}>
                        {" "}
                        {contactDetails.mobile}{" "}
                      </a>
                    </li>
                    <li>
                      <i className="ion-android-mail"></i>{" "}
                      <a href={`mailto:${contactDetails.email}`}>
                        {contactDetails.email}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/*Off Canvas Widget Social Start*/}
              <div className="off-canvas-widget-social">
                {socialMedia.facebook ? (
                  <a
                    target={"_blank"}
                    href={socialMedia.facebook}
                    title="Facebook"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                ) : (
                  ""
                )}
                {socialMedia.twitter ? (
                  <a
                    target={"_blank"}
                    href={socialMedia.twitter}
                    title="Twitter"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                ) : (
                  ""
                )}
                {socialMedia.linkedin ? (
                  <a
                    target={"_blank"}
                    href={socialMedia.linkedin}
                    title="LinkedIn"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                ) : (
                  ""
                )}
                {socialMedia.youtube ? (
                  <a
                    target={"_blank"}
                    href={socialMedia.youtube}
                    title="Youtube"
                  >
                    <i className="fa fa-youtube-play"></i>
                  </a>
                ) : (
                  ""
                )}
              </div>
              {/*Off Canvas Widget Social End*/}
            </div>
          </div>
        </div>
      </div>

      {/*=======  End of offcanvas mobile menu  =======*/}
      {/*====================  search overlay ====================*/}

      <div className="search-overlay" id="search-overlay">
        <a
          href="javascript:void(0)"
          className="close-search-overlay"
          id="close-search-overlay"
        >
          <i className="ion-ios-close-empty"></i>
        </a>

        {/*=======  search form  =======*/}

        <div
          className="search-form"
          onSubmit={(evt) => {
            evt.preventDefault();
            document.querySelector("#search-overlay").style.display = "none";
            history.push(`/search?q=${query}`);
          }}
        >
          <form action="#">
            <input
              type="search"
              value={query}
              onChange={(evt) => {
                setQuery(evt.target.value);
              }}
              placeholder="Search entire store here ..."
            />
            <button type="submit">
              <i className="ion-android-search"></i>
            </button>
          </form>
        </div>

        {/*=======  End of search form  =======*/}
      </div>

      {/*====================  End of search overlay  ====================*/}
      {/* scroll to top  */}
      <div id="scroll-top">
        <span>Back to top</span>
        <i className="ion-chevron-right"></i>
        <i className="ion-chevron-right"></i>
      </div>
      {/* end of scroll to top */}

      {/*====================  End of header area  ====================*/}
      {/*main-search start */}
      {/* {AddLibrary("/assets/js/vendors.js")} */}
      {/* {AddLibrary("/assets/js/active.js")} */}

      {/* {AddLibrary("/assets/js/popper.min.js")}
      {AddLibrary("/assets/js/bootstrap.min.js")}
      {AddLibrary("/assets/js/plugins.js")}
      {AddLibrary("/assets/js/ajax-mail.js")}
      {AddLibrary("/assets/js/main.js")} */}
    </>
  );
}

export default Header;
