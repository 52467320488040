import React, { useEffect, useRef, useState, useContext } from "react";
import ProductCard from "../components/ProductCard";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import { SERVER_URL } from "../config";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";

import { CustomerContext } from "../Routes";
import $ from "jquery";

import Breadcrumb from "../components/Breadcrumb";
import NewsLater from "../components/NewsLater";
import Header from "../layouts/Header";
import Rating from "react-rating";
import MultiRangeSlider from "../components/multiRangeSlider/MultiRangeSlider";
import { CategorySkeletonLoader } from "../components/CategorySkeletonLoader";
import PageNotFound from "../components/PageNotFound";

// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = false;
  document.body.appendChild(script);
}

const SearchedItems = () => {
  const history = useHistory();
  const search = useLocation().search;
  const q = new URLSearchParams(search).get("q");
  console.log(q);
  const { state, dispatch } = useContext(CustomerContext);
  const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
  const {
    parCatSlug = null,
    subCatSlug = null,
    childCatSlug = null,
  } = useParams();
  const [productsLoading, setProductsLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const [addWishlistLoading, setAddWishlistLoading] = useState(false);
  const [removeWishlistLoading, setRemoveWishlistLoading] = useState(false);
  const [addedToWishlist, setAddedToWishlist] = useState(false);
  const [myWishlists, setMyWishlists] = useState([]);
  const [removeFromWishlist, setRemoveFromWishlist] = useState(false);

  // Get Products
  useEffect(() => {
    setProductsLoading(true);
    fetch(`${SERVER_URL}/products?limit=0&skip=0&q=${q || null}`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProductsLoading(false);
        if (data.status == 200) {
          setProducts(data.body);

          // setProducts(data.body || {});
          // sortData();
        } else {
          toast.warning(data.message);
          const keys = Object.keys(data.errors);
          keys.forEach((key) => {
            toast.error(data.errors[key]);
          });
          setProducts([]);
        }
        window.scrollTo(0, 130);
        // console.log("Success:", data);
      })
      .catch((error) => {
        setProductsLoading(false);
        toast.warning(error);
        setProducts([]);
      });
  }, [q]);

  // Scroll to Div
  useEffect(() => {
    const reftp = document.getElementById("refTP");
    // myRef?.current.scrollIntoView();
  }, []);

  // Get Wishlists
  useEffect(() => {
    // setProductLoading(true);
    fetch(`${SERVER_URL}/wishlists/myWishlist`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setMyWishlists(data.body || []);
        } else {
          // toast.warning(data.message);
        }
      })
      .catch((error) => {
        // setProductLoading(false);
        toast.warning(error);
      });
  }, [addedToWishlist, removeFromWishlist]);

  // Add Product to Wishlist
  const addWishlistHandler = (evt, product) => {
    evt.preventDefault();

    // check customer is logedIn or not
    if (state && state.jwtToken) {
      setAddWishlistLoading(true);
      fetch(`${SERVER_URL}/wishlists`, {
        method: "POST", // or 'PUT'
        body: JSON.stringify({ product: product._id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAddWishlistLoading(false);
          if (data.status == 200) {
            toast.success(data.message);
            setAddedToWishlist(!addedToWishlist);
          } else {
            toast.warning(data.message);
          }
          // console.log("Success:", data);
        })
        .catch((error) => {
          setAddWishlistLoading(false);
          toast.error(error);
        });
    } else {
      toast.warning("Please Login First");
      history.push("/account/login");
    }
  };

  // Remove Product to Wishlist
  const removeWishlistHandler = (evt, wishListId) => {
    evt.preventDefault();
    setRemoveWishlistLoading(true);
    fetch(`${SERVER_URL}/wishlists/${wishListId}`, {
      method: "DELETE", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRemoveWishlistLoading(false);
        if (data.status == 200) {
          toast.success(data.message);
          setRemoveFromWishlist(!removeFromWishlist);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setRemoveWishlistLoading(false);
        toast.error(error);
      });
  };

  const addToCartHandler = async (fromWhere, product, quantity) => {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        name: product.name,
        slug: product.slug,
        parentCategory: product.parentCategory._id,
        subCategory: product.subCategory._id,
        childCategory: product.childCategory._id,

        productId: product._id,
        quantity: quantity || 1,
        price: product.sellingPrice,
        material: product.material._id,
        color: product.color._id,
        image: product.defaultImage,
        size: product.size._id,
        capacity: product.capacity,
        dimension: product.dimension,
      },
    });

    // add to database
    const response = await fetch(`${SERVER_URL}/cartProducts`, {
      body: JSON.stringify({ product: product._id }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();

    if (fromWhere == "BUY_NOW") {
      history.push("/checkout");
    }
  };

  return (
    <>
      <Header />

      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb title={"SHOP"} page={"Shop"} />
      {/*====================  End of breadcrumb area  ====================*/}

      {/*====================  shop page content area ====================*/}

      <div className="page-content-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  shop page wrapper  =======*/}
              <div className="page-wrapper">
                <div className="page-content-wrapper">
                  <div className="row">
                    <div className="col-lg-12"></div>

                    <div className="col-lg-12 order-1 order-lg-2">
                      {/*=======  shop page content  =======*/}
                      <div className="shop-page-content">
                        <div className="row shop-product-wrap grid three-column">
                          {productsLoading ? (
                            [...Array(9)].map(() => {
                              return (
                                <div className="col-md-3">
                                  <div className="skeleton">
                                    <div className="s-img"></div>
                                    <div className="s-line first"></div>
                                    <div className="s-line second"></div>
                                  </div>
                                </div>
                              );
                            })
                          ) : products.length ? (
                            products.map((item, index) => {
                              const discountPercent = Math.ceil(
                                100 -
                                  (Number.parseInt(item.sellingPrice) * 100) /
                                    Number.parseInt(item.mrp)
                              );

                              // Calculate avarage reviews
                              let avarageRating = 0;
                              const sumOfRatings = item.reviews.reduce(
                                (accumulator, current) => {
                                  return accumulator + current.rating;
                                },
                                0
                              );
                              avarageRating = (
                                sumOfRatings / item.reviews.length
                              ).toFixed(1);

                              // Check Item in available in the cart or not
                              let availableInCart = false;

                              if (state && state.cart.length) {
                                let available = state.cart.some((p) => {
                                  return p.productId == item._id;
                                });
                                if (available) availableInCart = true;
                              }

                              // Check Item in available in the wishlist or not
                              let availableInWishlist = false;

                              let available = myWishlists.some((p) => {
                                return p.product._id == item._id;
                              });
                              if (available) availableInWishlist = true;
                              // if (myWishlists.length) {
                              // }

                              return (
                                <div className="col-12 col-lg-3 col-md-3 col-sm-6">
                                  {/*=======  product grid view  =======*/}
                                  <ProductCard
                                    availableInCart={availableInCart}
                                    availableInWishlist={availableInWishlist}
                                    product={item}
                                    removeWishlistHandler={
                                      removeWishlistHandler
                                    }
                                    category={item.parentCategory}
                                    avarageRating={avarageRating}
                                    addToCartHandler={addToCartHandler}
                                    addWishlistHandler={addWishlistHandler}
                                    addWishlistLoading={addWishlistLoading}
                                    myWishlists={myWishlists}
                                    removeWishlistLoading={
                                      removeWishlistLoading
                                    }
                                    discountPercent={discountPercent}
                                  />
                                  {/*=======  End of product grid view  =======*/}
                                  {/*=======  list view product  =======*/}
                                  <div className="single-grid-product single-grid-product--list-view list-view-product">
                                    <div className="single-grid-product__image single-grid-product--list-view__image">
                                      <div className="single-grid-product__label">
                                        <span className="sale">
                                          -{discountPercent}
                                        </span>
                                        <span className="new">New</span>
                                      </div>
                                      <Link to={`/product/${item.slug}`}>
                                        <img
                                          width="600"
                                          height="800"
                                          src={item.defaultImage}
                                          className="img-fluid"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="800"
                                          src={
                                            item.images.length
                                              ? item.images[0].url
                                              : item.defaultImage
                                          }
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </Link>

                                      <div className="hover-icons">
                                        {/* Add to cart */}
                                        {availableInCart ? (
                                          <Link
                                            to="/cart"
                                            title="Available in Cart"
                                          >
                                            <i className="ion-bag text-danger"></i>
                                          </Link>
                                        ) : (
                                          <Link
                                            onClick={() => {
                                              addToCartHandler("", item);
                                            }}
                                            title="Add to Cart"
                                          >
                                            <i className="ion-bag"></i>
                                          </Link>
                                        )}
                                        {/* Wishlist */}
                                        {availableInWishlist ? (
                                          <Link
                                            title="Available in Wishlist"
                                            onClick={(evt) => {
                                              const wishList =
                                                myWishlists.filter((itn) => {
                                                  return (
                                                    itn.product._id == item._id
                                                  );
                                                });
                                              removeWishlistHandler(
                                                evt,
                                                wishList[0]._id
                                              );
                                            }}
                                          >
                                            <i className="ion-heart text-danger"></i>
                                          </Link>
                                        ) : (
                                          <Link
                                            onClick={(evt) => {
                                              addWishlistHandler(evt, item);
                                            }}
                                            title="Add to Wishlist"
                                          >
                                            <i className="ion-heart"></i>
                                          </Link>
                                        )}
                                        <a href="javascript:void(0)">
                                          <i className="ion-android-options"></i>
                                        </a>
                                        <a
                                          href="javascript:void(0)"
                                          data-bs-toggle="modal"
                                          data-bs-target="#quick-view-modal-container"
                                        >
                                          <i className="ion-android-open"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="single-grid-product__content single-grid-product--list-view__content">
                                      <div className="category">
                                        <Link
                                          href={`/${item.parentCategory.slug}`}
                                        >
                                          {item.parentCategory.name}
                                        </Link>
                                      </div>
                                      <h3 className="single-grid-product__title single-grid-product--list-view__title">
                                        <Link to={`/product/${item.slug}`}>
                                          {item.name}
                                        </Link>
                                      </h3>
                                      <div className="rating">
                                        <Rating
                                          readonly
                                          emptySymbol="fa fa-star-o fa-1x"
                                          fullSymbol="fa fa-star fa-1x text-danger"
                                        />
                                      </div>
                                      <p className="single-grid-product__price single-grid-product--list-view__price">
                                        <span className="discounted-price">
                                          <i className="fa fa-inr"></i>
                                          {item.sellingPrice}
                                        </span>{" "}
                                        <span className="main-price discounted">
                                          <i className="fa fa-inr"></i>
                                          {item.mrp}
                                        </span>
                                      </p>
                                      <p className="single-grid-product--list-view__product-short-desc">
                                        Material: {item.material.name} <br />
                                      </p>
                                    </div>
                                  </div>
                                  {/*=======  End of list view product  =======*/}
                                </div>
                              );
                            })
                          ) : (
                            <PageNotFound text="Product Not Found !!!" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of shop page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of shop page content area  ====================*/}

      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {AddLibrary("/assets/js/vendors.js")}
      {AddLibrary("/assets/js/active.js")}
      {/* {AddLibrary(
        "https://ionden.com/a/plugins/ion.rangeSlider/static/js/ion-rangeSlider/ion.rangeSlider.js"
      )}
      {AddLibrary(
        "https://ionden.com/a/plugins/ion.rangeSlider/static/css/ion.rangeSlider.css"
      )} */}
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default SearchedItems;
