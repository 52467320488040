import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../config";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomerContext } from "../Routes";

import NewsLater from "../components/NewsLater";
import Breadcrumb from "../components/Breadcrumb";
import Loader from "../components/Loader";
import Header from "../layouts/Header";

const ForgotPassword = () => {
  const history = useHistory();
  const scrollRef = useRef(null);
  const [formData, setFormData] = useState({
    mobile: "",
  });

  const [loginLoading, setLoginLoading] = useState(false);
  const [regLoading, setRegLoading] = useState(false);
  const { state, dispatch } = useContext(CustomerContext);

  const submitHandler = (evt) => {
    setLoginLoading(true);
    evt.preventDefault();

    fetch(`${SERVER_URL}/customers/findAccount`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoginLoading(false);

        if (data.status == 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          toast.success("OTP Send on your Mobile !!", {
            position: toast.POSITION.TOP_RIGHT,
          });

          localStorage.setItem(
            "resetPassword",
            JSON.stringify({
              mobile: data.body.mobile,
              otp: Number(data.body.otp) * 2,
              token: data.body.token,
            })
          );
          history.push("/enter-otp");
        } else {
          const keys = Object.keys(data.errors);
          keys.forEach((error) => {
            toast.error(data.errors[error]);
          });

          toast.error(data.message);
        }
      })
      .catch((error) => {
        setLoginLoading(false);
        console.error("Error:", error);
      });
  };

  // Scroll To Top
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, []);

  return (
    <>
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb title="Forgot Password" page="forgot-password" />
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div className="page-content-area" ref={scrollRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div className="page-wrapper">
                <div className="page-content-wrapper">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-6 m-auto">
                      {/* Forget Password*/}
                      <form action="#" onSubmit={submitHandler}>
                        <div className="login-form">
                          <h4 className="login-title">Forgot Password</h4>

                          <div className="row">
                            <div className="col-md-12 col-12">
                              <label>Mobile Number *</label>
                              <input
                                type="tel"
                                value={formData.mobile}
                                placeholder="Mobile Number"
                                onChange={(evt) => {
                                  setFormData({
                                    ...formData,
                                    mobile: evt.target.value,
                                  });
                                }}
                              />
                            </div>

                            <div className="col-sm-6"></div>

                            <div className="col-sm-6 text-start text-sm-end">
                              <Link
                                to="/account/login"
                                className="forget-pass-link"
                              >
                                {" "}
                                Back to Login?
                              </Link>
                            </div>

                            <div className="col-md-12">
                              <button
                                className="register-button"
                                disabled={loginLoading ? true : false}
                              >
                                {loginLoading ? <Loader /> : "Find Account"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default ForgotPassword;
