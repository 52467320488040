import React, { useContext, useState, useEffect, useRef } from "react";
import { CustomerContext } from "../Routes";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { SERVER_URL } from "../config";
import Loader from "../components/Loader";
import Header from "../layouts/Header";
const Cart = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(CustomerContext);
  const { cart = [] } = state;
  const pincodeRef = useRef(null);
  const checkoutRef = useRef(null);

  const [coupons, setCoupons] = useState([]);
  const [enteredCoupon, setEnteredCoupon] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState({
    code: "",
    discount: "",
    discountType: "",
  });

  const [subtotal, setSubtotal] = useState("");
  const [adonTotal, setAdonTotal] = useState("");
  const [discountWithCoupon, setDiscountWithCoupon] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [totalAmountAfterAdon, setTotalAmountAfterAdon] = useState("");
  const [adonProductModel, setAdonProductModel] = useState(false);

  const [enteredPincode, setEnteredPincode] = useState(
    JSON.parse(localStorage.getItem("pincode")) || ""
  );
  const [pincodeValidating, setPincodeValidating] = useState(false);

  const [validPincode, setValidPincode] = useState("");

  const [setting, setSetting] = useState({
    deliveryCharge: [],
  });

  const [shippingCost, setShippingCost] = useState(0);

  // Calculate amount
  useEffect(() => {
    // Calculate subtotal
    let sub_total = cart
      .map((product) => product.price * product.quantity)
      .reduce((prev, curr) => prev + curr, 0);

    setSubtotal(sub_total);

    let delvryCharge = 0;
    setting.deliveryCharge.map((item) => {
      if (sub_total <= Number(item.maxAmount)) {
        delvryCharge = item.charge;
      }
    });

    setShippingCost(delvryCharge);

    setTotalAmount(sub_total);
  }, [cart, setting.deliveryCharge]);

  useEffect(() => {
    fetch(`${SERVER_URL}/settings`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      // body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setSetting(data.body);
        } else {
          toast.warn(data.message);
        }
      })
      .catch((error) => {
        console.error("Header Error:", error);
        toast.error(error);
      });
  }, []);

  const validatePincodeHandler = (evt) => {
    evt.preventDefault();
    setPincodeValidating(true);
    fetch(`${SERVER_URL}/pincodes?pincode=${enteredPincode}`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      // body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          if (data.body.length) {
            toast.error(
              `${enteredPincode} Pincode is not Available for Delivery`
            );
          } else {
            toast.success(
              `${enteredPincode} Pincode is Available for Delivery`
            );
            setValidPincode(enteredPincode);
            localStorage.setItem("pincode", enteredPincode);
            history.push("/checkout");
          }
        } else {
          const errors = Object.keys(data.errors);
          for (let i = 0; i < errors.length; i++) {
            toast.error(data.errors[errors[i]]);
          }
          toast.error(data.message);
        }
        setPincodeValidating(false);
      })
      .catch((error) => {
        console.error("Header Error:", error);
        toast.error(error);
        setPincodeValidating(false);
      });
  };

  const checkoutHandler = (evt) => {
    evt.preventDefault();
    // history.push("/checkout");

    if (validPincode) {
      history.push("/checkout");
    } else {
      if (pincodeRef.current) {
        pincodeRef.current.focus();
        pincodeRef.current.scrollIntoView({ behavoiur: "smooth" });
      }
      toast.warn("Please Validate your Pincode");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Add facebook pixel
    window.fbq("track", "AddToCart");
  }, []);

  return (
    <>
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <div class="breadcrumb-area section-space--half">
        <div class="container wide">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  breadcrumb wrpapper  =======*/}
              <div class="breadcrumb-wrapper breadcrumb-bg">
                {/*=======  breadcrumb content  =======*/}
                <div class="breadcrumb-content">
                  <h2 class="breadcrumb-content__title">Cart</h2>
                  <ul class="breadcrumb-content__page-map">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li class="active">Cart</li>
                  </ul>
                </div>
                {/*=======  End of breadcrumb content  =======*/}
              </div>
              {/*=======  End of breadcrumb wrpapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div class="page-content-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div class="page-wrapper">
                <div class="page-content-wrapper">
                  {cart.length ? (
                    <>
                      <form action="#">
                        {/*=======  cart table  =======*/}

                        <div class="cart-table table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th class="pro-thumbnail">Image</th>
                                <th class="pro-title">Product</th>
                                <th class="pro-price">Price</th>
                                <th class="pro-quantity">Quantity</th>
                                <th class="pro-subtotal">Total</th>
                                <th class="pro-remove">Remove</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cart &&
                                cart.map((item, index) => {
                                  return (
                                    <tr>
                                      <td class="pro-thumbnail">
                                        <a href="single-product.html">
                                          <img
                                            width="600"
                                            height="800"
                                            src={item.image}
                                            class="img-fluid"
                                            alt="Product"
                                          />
                                        </a>
                                      </td>
                                      <td class="pro-title">
                                        <Link to={`/product/${item.slug}`}>
                                          {item.name}
                                        </Link>
                                      </td>
                                      <td class="pro-price">
                                        <span>
                                          <i
                                            class="fa fa-inr"
                                            aria-hidden="true"
                                          ></i>
                                          {item.price}
                                        </span>
                                      </td>
                                      <td class="pro-quantity">
                                        <div class="quantity-selection d-flex justify-content-center">
                                          <button
                                            className="btn btn-dark"
                                            type="button"
                                            onClick={(evt) => {
                                              evt.preventDefault();
                                              if (item.quantity > 1) {
                                                dispatch({
                                                  type: "DECREASE_QUANTITY",
                                                  payload: {
                                                    productId: item.productId,
                                                  },
                                                });
                                              } else {
                                                dispatch({
                                                  type: "REMOVE_FROM_CART",
                                                  payload: {
                                                    productId: item.productId,
                                                  },
                                                });
                                              }
                                            }}
                                          >
                                            -
                                          </button>
                                          <input
                                            type="number"
                                            value={item.quantity}
                                            min="1"
                                          />
                                          <button
                                            className="btn btn-dark"
                                            type="button"
                                            onClick={(evt) => {
                                              evt.preventDefault();
                                              if (
                                                item.quantity <
                                                item.maxOrderQuantity
                                              ) {
                                                dispatch({
                                                  type: "INCREASE_QUANTITY",
                                                  payload: {
                                                    productId: item.productId,
                                                  },
                                                });
                                              } else {
                                                toast.warning(
                                                  `Allowed only ${item.maxOrderQuantity} Items per User`
                                                );
                                              }
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </td>
                                      <td class="pro-subtotal">
                                        <span>
                                          <i
                                            class="fa fa-inr"
                                            aria-hidden="true"
                                          ></i>
                                          {item.quantity * item.price}
                                        </span>
                                      </td>
                                      <td class="pro-remove">
                                        <Link
                                          to="#"
                                          onClick={(evt) => {
                                            evt.preventDefault();
                                            dispatch({
                                              type: "REMOVE_FROM_CART",
                                              payload: {
                                                productId: item.productId,
                                              },
                                            });
                                          }}
                                        >
                                          <i class="fa fa-trash-o"></i>
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>

                        {/*=======  End of cart table  =======*/}
                      </form>

                      <div class="row">
                        <div class="col-lg-6 col-12">
                          {/*=======  Calculate Shipping  =======*/}

                          <div class="calculate-shipping">
                            {/* <h4>Check your pin code</h4> */}
                            <form action="#" onSubmit={validatePincodeHandler}>
                              <div class="row">
                                {/* <div class="col-md-6 col-12">
                                  <select class="nice-select">
                                    <option>Bangladesh</option>
                                    <option>China</option>
                                    <option>country</option>
                                    <option>India</option>
                                    <option>Japan</option>
                                  </select>
                                </div> */}
                                {/* <div class="col-md-6 col-12">
                                  <select class="nice-select">
                                    <option>Dhaka</option>
                                    <option>Barisal</option>
                                    <option>Khulna</option>
                                    <option>Comilla</option>
                                    <option>Chittagong</option>
                                  </select>
                                </div> */}
                                <div class="col-md-6 col-12">
                                  <input
                                    ref={pincodeRef}
                                    value={enteredPincode}
                                    onChange={(evt) => {
                                      setEnteredPincode(evt.target.value);
                                    }}
                                    type="text"
                                    placeholder="Postcode / Zip"
                                  />
                                </div>
                                <div class="col-md-6 col-12">
                                  <button className="submit-btn">
                                    {pincodeValidating ? (
                                      <Loader />
                                    ) : (
                                      "Validate"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>

                          {/*=======  End of Calculate Shipping  =======*/}

                          {/*=======  Discount Coupon  =======*/}

                          {/* <div class="discount-coupon">
                        <h4>Discount Coupon Code</h4>
                        <form action="#">
                          <div class="row">
                            <div class="col-md-6 col-12">
                              <input type="text" placeholder="Coupon Code" />
                            </div>
                            <div class="col-md-6 col-12">
                              <input type="submit" value="Apply Code" />
                            </div>
                          </div>
                        </form>
                      </div> */}

                          {/*=======  End of Discount Coupon  =======*/}
                        </div>

                        <div class="col-lg-6 col-12 d-flex">
                          {/*=======  Cart summery  =======*/}

                          <div class="cart-summary">
                            <div class="cart-summary-wrap">
                              <h4>Cart Summary</h4>
                              <p>
                                Sub Total{" "}
                                <span>
                                  <i class="fa fa-inr" aria-hidden="true"></i>
                                  {subtotal}
                                </span>
                              </p>
                              {/* <p>
                                Shipping Cost{" "}
                                <span>
                                  <i class="fa fa-inr" aria-hidden="true"></i>
                                  {shippingCost}
                                </span>
                              </p> */}
                              <h2>
                                Grand Total{" "}
                                <span>
                                  <i class="fa fa-inr" aria-hidden="true"></i>
                                  {totalAmount}
                                </span>
                              </h2>
                            </div>
                            <div class="cart-summary-button">
                              <button
                                ref={checkoutRef}
                                onClick={checkoutHandler}
                                class="checkout-btn"
                              >
                                Checkout
                              </button>
                              {/* <button class="update-btn">Update Cart</button> */}
                            </div>
                          </div>

                          {/*=======  End of Cart summery  =======*/}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="alert alert-danger">No Product in Cart</div>
                  )}
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <div class="newsletter-area section-space--inner">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="newsletter-wrapper">
                <p class="small-text">Special Ofers For Subscribers</p>
                <h3 class="title">Ten Percent Member Discount</h3>
                <p class="short-desc">
                  Subscribe to our newsletters now and stay up to date with new
                  collections, the latest lookbooks and exclusive offers.
                </p>

                <div class="newsletter-form">
                  <form id="mc-form" class="mc-form">
                    <input
                      type="email"
                      placeholder="Enter Your Email Address Here..."
                      required
                    />
                    <button type="submit" value="submit">
                      SUBSCRIBE
                    </button>
                  </form>
                </div>
                {/* mailchimp-alerts Start */}
                <div class="mailchimp-alerts">
                  <div class="mailchimp-submitting"></div>
                  {/* mailchimp-submitting end */}
                  <div class="mailchimp-success"></div>
                  {/* mailchimp-success end */}
                  <div class="mailchimp-error"></div>
                  {/* mailchimp-error end */}
                </div>
                {/* mailchimp-alerts end */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default Cart;
