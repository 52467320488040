import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import NewsLater from "../components/NewsLater";
import Breadcrumb from "../components/Breadcrumb";
import Header from "../layouts/Header";
import HelmetMetaData from "../components/HelmetMetaData";
const ShippingPolicies = () => {
  // Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetMetaData
        title={"Our Shipping Policies | Ferra Indica"}
        description={"Our Shipping Policies | Ferra Indica"}
      />
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb title={"Shipping Policies"} page={"Shipping Policies"} />
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div class="page-content-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div class="page-wrapper">
                <div class="page-content-wrapper">
                  <div class="row">
                    <div class="col-lg-12 order-1 order-lg-2">
                      {/*=======  blog page content  =======*/}
                      <div class="blog-page-content">
                        {/*=======  blog post container  =======*/}

                        <div class="blog-single-post-container">
                          {/*=======  post title  =======*/}

                          <h3 class="post-title"> Shipping Policies </h3>

                          {/*=======  End of Post media  =======*/}

                          {/*=======  Post content  =======*/}

                          <div class="post-content">
                            {/* <h4>Shipping Policies</h4> */}
                            <ol className="footer-navigation">
                              <li>
                                We ship across India. We also provide the Cash
                                on Delivery payment option**.
                                <p className="">
                                  {" "}
                                  <em>
                                    Please refer to our shipping table below:
                                  </em>{" "}
                                </p>
                                <table>
                                  <tr>
                                    <th>Order Total</th>
                                    <th>Shipping Charges</th>
                                  </tr>
                                  <tr>
                                    <td>Below ₹1000 (Prepaid)</td>
                                    <td>₹100</td>
                                  </tr>
                                  <tr>
                                    <td>₹1000 and above (Prepaid)</td>
                                    <td>FREE!</td>
                                  </tr>
                                  <tr>
                                    <td>Below ₹1000 (Cash on Delivery)</td>
                                    <td>₹150</td>
                                  </tr>
                                  <tr>
                                    <td>₹1000 and above (Cash on Delivery)</td>
                                    <td>₹75</td>
                                  </tr>
                                </table>
                              </li>
                              <li>
                                We give the estimated time of delivery on the
                                shipping page. However, these are indicative and
                                depend on our shipping partner.
                              </li>
                              <li>
                                The delivery times are subject to location,
                                distance and our logistics partners. We are not
                                liable for any delays in delivery by the Courier
                                Company/postal authorities but will help you
                                track down a package through our partner courier
                                services.
                              </li>
                              <li>
                                Your purchases may reach you from various
                                locations in more than one package. But rest
                                assured, you will be charged one delivery fee
                                for the entire order.
                              </li>
                              <li>
                                As soon as your package ships, we will email you
                                your package tracking information.
                              </li>
                              <li>
                                Cash On Delivery option is not a trial option
                                and payment is necessary before accepting the
                                order. Any refusal of COD orders will result in
                                deactivation of the COD option for future orders
                              </li>
                              <li>
                                In some pin codes, our shipping partners do not
                                support COD and hence the option might be
                                unavailable. For any queries, please contact our
                                customer WhatsApp helpline.
                              </li>
                              <li>
                                In case of any special time bound delivery
                                requirements, please share an email with us and
                                leave comments in the order about the requested
                                time of delivery. We will try our best to
                                fulfill the order within the time requested but
                                we cannot guarantee the same
                              </li>
                              <li>
                                Orders placed in India are catered by safe and
                                reliable courier companies like Delhivery, Ecom
                                Express and Amazon Shipping
                              </li>
                              <li>
                                We are bound in coverage by their reach even
                                though we use some of India&#39;s largest
                                logistics companies for shipping. In case your
                                address is in a location not served by them we
                                would contact you to find an alternative
                                solution to make your products reach you.
                              </li>
                              <li>
                                For any cancellations, we require an email
                                within 4 hours of the order being placed. If the
                                order is shipped out, cancellations will not be
                                accepted. We will be unable to process any
                                refund if an order is cancelled at the doorstep
                                or if there have been 3 failed attempts to
                                deliver the parcel by our delivery partner
                              </li>
                              <li>
                                Look out for international shipping coming soon
                                at Ferra Indica. We currently do not ship to any
                                PO Boxes or APO addresses. In case of any
                                queries and doubts, we are happy if you write to
                                us at info@ferraindica.com
                              </li>
                            </ol>
                          </div>

                          {/*=====  End of Share post area  ======*/}

                          {/*=======  related post  =======*/}

                          {/*=======  End of related post  =======*/}
                        </div>
                      </div>
                      {/*=======  End of blog page content  =======*/}
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default ShippingPolicies;
